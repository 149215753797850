import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper.jsx";

import MenuIcon from "@material-ui/icons/Menu";
import AppSettings from "app-settings";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { ReactComponent as BackToTopArrowIcon } from "../../assets/img/backToTop_Arrow.svg";
import { ReactComponent as BackToTopLineIcon } from "../../assets/img/backToTop_Line.svg";
import { ReactComponent as BJSTwoManLogo } from "../../assets/img/BJSTwoMan_Logo.svg";
import { ReactComponent as EnvironmentSwitchIcon } from "../../assets/img/icons_EnviromentSwitch.svg";
import { ReactComponent as SignOutArrowIcon } from "../../assets/img/icons_SignOutArrow.svg";
import { ReactComponent as SignOutNoArrowIcon } from "../../assets/img/icons_SignOutNoArrow.svg";
import { ReactComponent as NavDeliveryScheduleIcon } from "../../assets/img/navIcons_DeliverySchedule.svg";
import { ReactComponent as NavDispatchesIcon } from "../../assets/img/navIcons_Dispatches.svg";
import { ReactComponent as NavHomeIcon } from "../../assets/img/navIcons_Home.svg";
import { ReactComponent as NavOrdersIcon } from "../../assets/img/navIcons_Orders.svg";
import { ReactComponent as NavProductsIcon } from "../../assets/img/navIcons_Products.svg";
import { ReactComponent as NavSettingsIcon } from "../../assets/img/navIcons_Settings.svg";
import APIService from "../../services/APIService.js";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import "./layout1.scss";
import ChangeStorePopup from "./Layout1Components/ChangeStorePopup.jsx";
import StoresListDropdown from "./Layout1Components/StoresList.jsx";

window.addEventListener("focus", function () {
  let selectedStoreId = document.getElementById("selected-store");
  if (selectedStoreId) {
    selectedStoreId = selectedStoreId.getAttribute("data-selected-store-id");
    let stores = localStorage.getItem("stores");
    if (stores && stores.length > 0) {
      stores = JSON.parse(stores);
      for (let i = 0; i < stores.length; i++) {
        stores[i].is_selected = stores[i].id === selectedStoreId;
      }
    }
    if (!stores) {
      // document.location = "/error/401";
      document.location = "/help";
    } else {
      localStorage.setItem("stores", JSON.stringify(stores));
    }
  }
});

const app_container = function (data) {
  try {
    const container =
      window !== undefined ? () => window.document.body : undefined;
    const { window } = data;
    return container;
  } catch (e) {
    return undefined;
  }
};

const scrollToRef = () =>
  document.getElementById("main-section").scrollTo(0, 0);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
const height = window.innerHeight - 74;
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  loaderHeight: {
    height: `${height - 68}px`,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  main: {
    background: "white",
    marginTop: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
    overflow: "hidden",
    height: `${height}px`,
  },
  mainPage: {
    float: "left",
    width: "100%",
    minHeight: `${height - 100}px`,
  },
  horizontalLine: {
    paddingBottom: "3px!important",
    background: "#3A3630 !important",
  },
  main2: {
    height: `${height}px`,
    overflowY: "scroll",
    scrollBehavior: "smooth",
  },
  logo: {
    display: "block",
    width: "auto",
    margin: "auto",
    padding: "6px 0px",
  },
  menuButton: {
    marginRight: 0,
    borderRight: "1px solid #dbd4c488 !important",
    borderRadius: "0!important",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: "67px",
    width: drawerWidth,
    background: "#F0F0F0",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar(data) {
  const height = useWindowSize();

  const routes = data.routes;
  let store = localStorage.getItem("stores");

  if (store == null) {
    data.history.push("/refine/store");
    return <div></div>;
  }

  const [linkDisabled, setlinkDisabled] = React.useState(false);
  const [isLiveOrStaging, setIsLiveOrStaging] = React.useState(true);

  const [isDispatchDisabled, setIsDispatchDisabled] = React.useState(false);
  // setIsDispatchDisabled(
  //   APIService.GetStoreConfig("batch_orders_auto") == false &&
  //   APIService.GetStoreConfig("batch_orders_manual") == false
  // );

  if (!APIService.CheckStoreApiExist() && store != null) {
    if (
      data &&
      "history" in data &&
      "location" in data.history &&
      "/app-settings" !== data.history.location.pathname
    )
      data.history.push("/app-settings");
    if (linkDisabled === false) {
      setlinkDisabled(true);
    }
  }
  const isDispatchDisabledFunction = (val) => {
    setIsDispatchDisabled(!val);
  };

  const linkDisabledFunction = () => {
    setlinkDisabled((prev) => [prev, false]);
  };

  const openIsStagingOrLiveFunction = (isPopup) => {
    setOpenIsStagingOrLive(isPopup);
  };

  const classes = useStyles();

  const HandleActivePage = (page) => {
    if (
      data &&
      page &&
      "location" in data &&
      "pathname" in data.location &&
      data.history.location.pathname === page
    )
      return "NavlistActive";
    else return "";
  };

  const menuId = "primary-search-account-menu";

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isStoreConfigCalled, setIsStoreConfigCalled] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [Help, setHelp] = React.useState(null);

  const getHelp = (Help) => {
    setHelp(Help);
  };
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [orderDispatchProductsCount, setOrderDispatchProductsCount] =
    React.useState({ TotalOrders: 0, TotalProducts: 0, TotalDispatches: 0 });

  const getOrderDispatchProductsCount = () => {
    setMobileOpen(false);
    APIService.GET(
      AppSettings.GetOrderDispatchProductsCount +
        "?lastNMonthOrder=" +
        (localStorage.getItem("lastNMonthOrder")
          ? localStorage.getItem("lastNMonthOrder")
          : 1)
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          setOrderDispatchProductsCount(response.result);
        } else {
          setErrorAlert(
            <ApiResponseError
              response={response}
              onClick={() => {
                setErrorAlert(null);
              }}
            />
          );
        }
      })
      .catch(() => {
        setErrorAlert(
          <ApiResponseError
            onClick={() => {
              setErrorAlert(null);
            }}
            response={{
              message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
            }}
          />
        );
      });
  };

  const GetStore = () => {
    APIService.GET(AppSettings.GetStoresAPI)
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          var store = response.result.stores.filter(
            (x) => x.is_selected === true
          );
          if (store.length === 0) {
            store = response.result.stores[0];
          }

          localStorage.setItem(
            "stores",
            JSON.stringify(response.result.stores)
          );

          APIService.GET(AppSettings.GetSettingsAPI)
            .then((response) => response.json())
            .then((response) => {
              if (response && response.success) {
                if (response.result.id != null) {
                  // this.setState({ isExist: true });
                  localStorage.setItem(
                    "store_config",
                    JSON.stringify(response.result)
                  );
                }
              }
              setIsDispatchDisabled(
                response.result.batch_orders_auto === false &&
                  response.result.batch_orders_manual === false
              );
              setIsLiveOrStaging(response.result.is_live_api);
              setIsStoreConfigCalled(true);
            })
            .catch(() => {
              setErrorAlert(
                <ApiResponseError
                  response={response}
                  onClick={() => {
                    // this.props.history.push("/error/401");
                    this.props.history.push("/help");
                  }}
                />
              );
            });

          //if store is not configured then send user to settings page else to order page
        } else {
          setErrorAlert(
            <ApiResponseError
              response={response}
              onClick={() => {
                // this.props.history.push("/error/401");
                this.props.history.push("/help");
              }}
            />
          );
        }
      })
      .catch(() => {
        setErrorAlert(
          <ApiResponseError
            onClick={() => {
              setErrorAlert(null);
            }}
            response={{
              message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
            }}
          />
        );
      });
  };

  React.useEffect(() => GetStore(), []);

  React.useEffect(() => {
    getOrderDispatchProductsCount();
  }, []);

  const drawer = (
    <div className="LeftBar">
      <Divider />
      <List>
        <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/"}
          disable={linkDisabled === true ? "true" : "false"}
        >
          <ListItem
            button
            key="home"
            className={"Navlist " + HandleActivePage("/")}
          >
            <ListItemIcon>
              <NavHomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <ListSubheader className="list-subheader">
          ORDER MANAGEMENT
        </ListSubheader>
        <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/orders"}
          disable={linkDisabled === true ? "true" : "false"}
        >
          <ListItem
            button
            key="orders"
            className={"Navlist " + HandleActivePage("/orders")}
          >
            <ListItemIcon>
              <NavOrdersIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
            <ListItemIcon className="navbar-count">
              <span id="total-orders-count-nav">
                {orderDispatchProductsCount.TotalOrders}
              </span>
            </ListItemIcon>
          </ListItem>
        </Link>
        <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/dispatches"}
          disable={linkDisabled === true ? "true" : "false"}
          style={{ display: isDispatchDisabled ? "none" : "block" }}
          id="dispatches-list"
        >
          <ListItem
            button
            key="dispatches"
            className={"Navlist " + HandleActivePage("/dispatches")}
          >
            <ListItemIcon>
              <NavDispatchesIcon />
            </ListItemIcon>
            <ListItemText primary="Dispatches" />
            <ListItemIcon className="navbar-count">
              <span>{orderDispatchProductsCount.TotalDispatches}</span>
            </ListItemIcon>
          </ListItem>
        </Link>
        <ListSubheader className="list-subheader">
          PRODUCT MANAGEMENT
        </ListSubheader>
        <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/products"}
          disable={linkDisabled === true ? "true" : "false"}
        >
          <ListItem
            button
            key="products"
            className={"Navlist " + HandleActivePage("/products")}
          >
            <ListItemIcon>
              <NavProductsIcon />
            </ListItemIcon>
            <ListItemText style={{ paddingLeft: "4px" }} primary=" Products" />
            <ListItemIcon className="navbar-count">
              <span id="total-product-count-nav">
                {orderDispatchProductsCount.TotalProducts}
              </span>
            </ListItemIcon>
          </ListItem>
        </Link>
        <ListSubheader className="list-subheader">DELIVERY</ListSubheader>
        <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/delivery-schedule"}
          disable={linkDisabled === true ? "true" : "false"}
        >
          <ListItem
            button
            key="delivery-schedule"
            className={"Navlist " + HandleActivePage("/delivery-schedule")}
          >
            <ListItemIcon>
              <NavDeliveryScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="Delivery Schedule" />
          </ListItem>
        </Link>
        <ListSubheader className="list-subheader">APPLICATION</ListSubheader>
        <Link to="/app-settings" onClick={getOrderDispatchProductsCount}>
          <ListItem
            button
            key="app-settings"
            className={"Navlist " + HandleActivePage("/app-settings")}
          >
            <ListItemIcon>
              <NavSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="App Settings" />
          </ListItem>
        </Link>
        {/* <Link
          onClick={getOrderDispatchProductsCount}
          to={linkDisabled === true ? "" : "/notifications"}
          disable={linkDisabled === true ? "true" : "false"}
        // style={{ display: "none" }}
        >
          <ListItem
            button
            key="notifications"
            className={"Navlist " + HandleActivePage("/notifications")}
          >
            <ListItemIcon>
              <NotificationsActiveSharpIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
        </Link> */}
      </List>
    </div>
  );
  const [openHelp, setOpenHelp] = React.useState(false);
  const [openIsStagingOrLive, setOpenIsStagingOrLive] = React.useState(false);
  const [footerMargin, setFooterMargin] = React.useState(0);
  const notistackRef = React.createRef();
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  return (
    <div className={classes.grow}>
      {errorAlert}
      <AppBar position="static" className="menu">
        <Toolbar>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://bjshomedelivery.com/"
            className="contact-support"
          >
            <BJSTwoManLogo className={classes.logo} />
          </a>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <StoresListDropdown />
            <Link to="/help" style={{ display: "flex" }}>
              <IconButton
                className="help menu-link "
                onClick={() => {
                  setOpenHelp(true);
                }}
              >
                <img src="/images/icons_Help.svg" />
                <span className="text-line">
                  <span className="text">Help</span>
                  <span className="line"></span>
                </span>
              </IconButton>
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <StoresListDropdown />
            <div className="live-staging menu-link mobile">
              <Button
                onClick={() => {
                  setOpenIsStagingOrLive(true);
                }}
                variant="contained"
                className={
                  " button " + (isLiveOrStaging ? "production" : "staging")
                }
                // endIcon={<EnvironmentSwitchIcon />}
              >
                <EnvironmentSwitchIcon />
                {/* {isLiveOrStaging
                  ? "Production"
                  : "Staging"} */}
              </Button>
              {/* {openIsStagingOrLive == true && (
                <ChangeStorePopup
                  IsStagingOrLive={openIsStagingOrLiveFunction}
                />
              )} */}
            </div>
            <IconButton
              aria-controls={menuId}
              aria-haspopup="true"
              className="sign-out menu-link mobile"
              onClick={() => {
                localStorage.clear();
                document.location = "https://www.bjshomedelivery.com/";
              }}
            >
              <div className="box-arrow">
                <SignOutArrowIcon className="arrow" />
                <SignOutNoArrowIcon />
              </div>{" "}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box className={classes.horizontalLine}></Box>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={app_container(data)}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {!isStoreConfigCalled && (
        <Box
          className={classes.main}
          style={{ height: `${height - 68}px` }}
          id="parent-main-section"
        >
          <LoadingOnPopupHelper className={classes.loaderHeight} />
        </Box>
      )}
      {isStoreConfigCalled && (
        <Box
          className={classes.main}
          style={{ height: `${height - 68}px` }}
          id="parent-main-section"
        >
          <Box
            mt={1}
            ref={myRef}
            p={1}
            className={classes.main2 + " main"}
            style={{
              height: `${height - 68}px`,
              paddingTop: "0px",
              marginTop: "8px",
            }}
            id="main-section"
          >
            <div className={classes.mainPage}>
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes
                    key={i}
                    // setIsLiveApi={setIsLiveApi}
                    BodyHeight={height}
                    notistackRef={notistackRef}
                    linkDisabledFunction={linkDisabledFunction}
                    isDispatchDisabledFunction={isDispatchDisabledFunction}
                    executeScroll={executeScroll}
                    setFooterMargin={(margin) => {
                      setFooterMargin(margin);
                    }}
                    getHelp={getHelp}
                    {...route}
                  />
                ))}
              </Switch>
            </div>

            <Box
              mt={1}
              p={1}
              className="footer"
              style={{ marginBottom: footerMargin + "px" }}
            >
              <p>
                © BJS Distribution Ltd, 2022 - All rights reserved.{" "}
                <a
                  href="https://bjshomedelivery.com/"
                  target="_blank"
                  rel={"noreferrer"}
                >
                  www.bjsfamily.com
                </a>
              </p>
              <button
                id="back-to-top"
                className="back-to-top"
                onClick={executeScroll}
              >
                <BackToTopLineIcon className="line" />
                <BackToTopArrowIcon className="arrow" />
              </button>
            </Box>
          </Box>
        </Box>
      )}
      <Dialog
        open={openHelp}
        onClose={() => {
          setOpenHelp(false);
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Help</DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {Help}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenHelp(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openIsStagingOrLive === true && (
        <ChangeStorePopup
          openIsStagingOrLiveFunction={openIsStagingOrLiveFunction}
          isLiveOrStaging={(res) => {
            setIsLiveOrStaging(res);
          }}
        />
      )}
    </div>
  );
}

PrimarySearchAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...props}
          getHelp={route.getHelp}
          BodyHeight={route.BodyHeight}
          notistackRef={route.notistackRef}
          // setIsLiveApi={route.setIsLiveApi}
          linkDisabledFunction={route.linkDisabledFunction}
          executeScroll={route.executeScroll}
          isDispatchDisabledFunction={route.isDispatchDisabledFunction}
          setFooterMargin={route.setFooterMargin}
          routes={route.routes}
        />
      )}
    />
  );
}
