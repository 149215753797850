import React, { Component } from "react";

import { Button, DialogActions, IconButton } from "@material-ui/core/";
import { ReactComponent as CancelIcon } from "../../assets/img/ctaIcons_Cancel.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as CheckIcon } from "../../assets/img/navIcons_Check.svg";

class APIResponsePopupHelper extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //       isSuccessOrFailure: props..status,
    //       message: props..message,
    // };
  }

  render() {
    return (
      <div className="order-response-popup">
        {this.props.result.success === true && (
          <div className="popup success-popup">
            <IconButton
              className="close-icon"
              onClick={this.props.successClose}
            >
              <CloseIcon />
            </IconButton>
            <div className="popup-body">
              <h1>
                <CheckIcon /> Success
              </h1>
              <div className="popup-content">
                <p>{this.props.result.message}</p>
                <DialogActions>
                  <Button
                    variant="contained"
                    className="button-cancel"
                    onClick={this.props.successClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            </div>
          </div>
        )}
        {this.props.result.success === false && (
          <div className="popup popup-failure">
            <IconButton
              className="close-icon"
              onClick={this.props.failureClose}
            >
              <CloseIcon />
            </IconButton>
            <div className="popup-body">
              <h1>
                <CancelIcon /> there was a problem
              </h1>
              <div className="popup-content">
                <p>{this.props.result.message}</p>
                <DialogActions>
                  <Button
                    variant="contained"
                    className="button-cancel failure"
                    onClick={this.props.failureClose}
                  >
                    Close
                  </Button>
                </DialogActions>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default APIResponsePopupHelper;
