import * as React from "react";
import AppSettings from "app-settings";
import { createBrowserHistory } from "history";
import { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import OrderActionHelper from "views/helper/OrderActionHelper.jsx";
import { ReactComponent as DispatchStockToBjsIcon } from "../../assets/img/ctaIcons_DispatchStockToBJS.svg";
import APIService from "../../services/APIService.js";
import ApiResponseError from "./ApiResponseError.jsx";

class AutoReleasePopupHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionButtonValue: false,
      errorAlert: null,
    };
    // this.ReleaseOrderAction = this.ReleaseOrderAction.bind(this);
  }
  // ReleaseOrderAction = (OrderIds) => {
  //   this.setState({ OrderActionResponsePopup: true });
  //   APIService.POST(AppSettings.ReleaseOrdersAPI, {
  //     order_ids: OrderIds,
  //     release: true,
  //   }).then((response) => response.json())
  //     .then((response) => {
  //       if (response && response.success) {
  //         this.setState({ OrderActionResponsePopup: response.result });
  //       } else {
  //         this.setState({
  //           errorAlert: <ApiResponseError response={response} />,
  //           loading: false,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       this.setState({
  //         errorAlert: <ApiResponseError response={{ "message": process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE }} />,
  //         loading: false,
  //       });
  //     });
  // };

  // Call when refresh the page
  componentDidMount() {
    // let history = createBrowserHistory();

    const queryParams = new URLSearchParams(window.location.search);
    let OrderNumbersStr = queryParams.get("id");

    if (OrderNumbersStr) {
      APIService.GET(
        AppSettings.GetOrdersWithShopifyId + "?ids=" + OrderNumbersStr
      )
        .then((response) => response.json())
        .then((response) => {
          if (response && response.success) {
            if (response.result.length > 0) {
              this.setState({
                actionButtonValue: response.result,
              });
            }
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    }
  }

  render() {
    return (
      <>
        {this.state.actionButtonValue && (
          <OrderActionHelper
            action="Release"
            actionTitle={"Release Stock to BJS"}
            actionIcon={<DispatchStockToBjsIcon />}
            Orders={this.state.actionButtonValue}
            onSuccessClose={() => {
              this.setState({ actionButtonValue: null });
              let history = createBrowserHistory();

              history.push(`/orders`);
            }}
            onCancelClose={() => {
              this.setState({ actionButtonValue: null });
              let history = createBrowserHistory();

              history.push(`/orders`);
            }}
          />
        )}
        {/* <OrderActionHelper
            id={this.state.actionButtonValue.ids}
            onClose={() => {
              this.setState({ actionButtonValue: null });
              let history = createBrowserHistory();

              history.push(`/orders`);
            }}
            action="Release"
            RefreshData={() => {
              this.setState({ actionButtonValue: null });
            }}
            actionTitle={"Release Stock to BJS"}
            actionIcon={<DispatchStockToBjsIcon />}
            // OrderInTab={this.state.filtration.orderStatus}
            OrderNumbers={this.state.actionButtonValue.orderNumbers}
            handleCloseAlertDialog={() => {
              () => {
                this.setState({ actionButtonValue: null });
                let history = createBrowserHistory();

                history.push(`/orders`);
              };
            }}
          /> */}
      </>
    );
  }
  // render() {
  //   return <div>
  //     {this.state.OrderActionResponsePopup && (
  //       <OrderActionResponsePopupHelper
  //         handleCloseAlertDialog={() => {
  //           this.setState({ OrderActionResponsePopup: true });
  //         }}
  //         result={this.state.OrderActionResponsePopup}
  //         refreshData={this.props.RefreshData}
  //       />
  //     )}
  //   </div>;
  // }
}

export default AutoReleasePopupHelper;
