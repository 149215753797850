import React, { Component } from "react";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as SearchIcon } from "../../assets/img/icons_Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as BackIcon } from "../../assets/img/navigationIcons_LeftArrow.svg";
import { ReactComponent as NextIcon } from "../../assets/img/navigationIcons_RightArrow.svg";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import HelpFooter from "./HelpFooter.jsx";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper.jsx";

import "./HelpPage.scss";
export default class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: null,
      search: "",
      filterSearch: [],
      categories: [],
      popularQuestions: [],
      questions: null,
    };
    this.Search = this.Search.bind(this);
    this.getQuestions = this.getQuestions.bind(this);
  }
  componentDidMount() {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_CMS_ENDPOINT_URL + "/faqs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ faqs: result });
      })
      .catch((error) => console.log("error", error));

    fetch(
      process.env.REACT_APP_CMS_ENDPOINT_URL +
        "/questions?popular_question=true",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ popularQuestions: result });
      })
      .catch((error) => console.log("error", error));

    fetch(
      process.env.REACT_APP_CMS_ENDPOINT_URL + "/categories",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ categories: result });
      })
      .catch((error) => console.log("error", error));
  }

  getQuestions = (event) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_CMS_ENDPOINT_URL + "/questions", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ questions: result });

        this.Search(event);
      })
      .catch((error) => console.log("error", error));
  };
  Search = (event) => {
    const { value } = event.target;

    let filteredRows = [];
    if (this.state.questions) {
      try {
        this.state.questions.forEach((row) => {
          // row.faq.forEach(row2 => {
          if (row.question.toLowerCase().match(value.toLowerCase()))
            filteredRows.push({
              slug: row.categories[0].slug + "/" + row.slug,
              question: row.question,
            });

          if (filteredRows.length == 3) {
            throw "BreakError";
          }
          // });
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.getQuestions(event);
    }

    this.setState({ search: value, filterSearch: filteredRows });
  };
  render() {
    return (
      <div id="help-page" className="help">
        {this.state.faqs &&
          this.state.categories &&
          this.state.popularQuestions && (
            <>
              <div className="help-center">
                <Link to="/">
                  <IconButton className="back-button">
                    <BackIcon />
                  </IconButton>
                </Link>
                <div className="body">
                  <h1 className="pageTitle">
                    {this.state.faqs[0].header.heading}
                  </h1>
                  <p className="subtitle">
                    {this.state.faqs[0].header.description}
                  </p>
                  {/* <div className="store-refine-menu">
                    <Link className={"common-menu"} to="/app-settings">
                      App Settings
                    </Link>
                    <Link
                      className={"common-menu menu-store-refine"}
                      to="/refine/store"
                    >
                      Link Your Store
                    </Link>
                  </div> */}
                  <div className="search-box-result">
                    <TextField
                      placeholder="Start by typing a question or using a keyword..."
                      variant="outlined"
                      className="search-box"
                      value={this.state.search}
                      onChange={this.Search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className="search-icon"
                            position="start"
                          >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: this.state.search ? (
                          <InputAdornment className="close-icon" position="end">
                            <CloseIcon
                              onClick={() => {
                                this.setState({ search: "", filterSearch: [] });
                              }}
                            />
                          </InputAdornment>
                        ) : (
                          ""
                        ),
                      }}
                    />
                    <ul type="none" className="search-result">
                      {this.state.search &&
                        this.state.filterSearch.length > 0 &&
                        this.state.filterSearch.map((row, index) => (
                          <li key={index}>
                            <Link to={"/help/" + row.slug}>{row.question}</Link>
                          </li>
                        ))}
                      {this.state.search &&
                        this.state.filterSearch.length == 0 && (
                          <li>No Result Found</li>
                        )}
                    </ul>
                    {this.state.search && this.state.filterSearch.length == 0 && (
                      <p className="unable-to-find">
                        Unable to find what you&#39;re looking for?{" "}
                        <a href="javascript:void(0);">
                          Contact our support team
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="popular-question">
                <h5>{this.state.faqs[0].popular_questions.title}</h5>
                <div className="slider">
                  <div
                    className="slider-button back"
                    onClick={() => {
                      document.querySelector("#slider-main").scrollLeft -= 300;
                    }}
                  >
                    <BackIcon />
                  </div>
                  <div className="slider-main" id="slider-main">
                    {this.state.popularQuestions.map((row, key) => (
                      <Link
                        key={key}
                        to={"/help/" + row.categories[0].slug + "/" + row.slug}
                      >
                        {row.question}
                      </Link>
                    ))}
                  </div>
                  <div
                    className="slider-button next"
                    onClick={() => {
                      document.querySelector("#slider-main").scrollLeft += 300;
                    }}
                  >
                    <NextIcon />
                  </div>
                </div>
              </div>
              <div className="question-category">
                <Grid container spacing={3}>
                  {this.state.categories.map((row, index) => (
                    <Grid key={index} item md={4} className="card-grid">
                      <Card className="card">
                        <CardContent>
                          <Link key={index} to={"/help/" + row.slug}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h3"
                              className="card-title"
                            >
                              {row.heading}
                              <IconButton className="next-button">
                                <NextIcon />
                              </IconButton>
                            </Typography>
                          </Link>
                          <Typography
                            variant="body2"
                            className="card-body"
                            component="p"
                          >
                            {row.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
              {/* <HelpFooter /> */}
            </>
          )}
        {(!this.state.faqs ||
          !this.state.categories ||
          !this.state.popularQuestions) && (
          <div
            style={{ height: window.innerHeight - 175 + "px", display: "flex" }}
          >
            <LoadingOnPopupHelper />
          </div>
        )}
      </div>
    );
  }
}
