import APIService from "../../services/APIService.js";

export default class OrderStatusEnum {
  static NEW() {
    return 1;
  }
  static DELETED() {
    return 2;
  }
  static ORDER_CREATED() {
    return 3;
  }
  static MARKED_RELEASED() {
    return 4;
  }
  static ORDER_RELEASED() {
    return 5;
  }
  static ORDER_UN_RELEASED() {
    return 6;
  }
  static PRODUCT_RECEIVED_INTO_WAREHOUSE() {
    return 7;
  }
  static ALL_DELIVERY_PRODUCTS_RECEIVED_AT_DEPOT() {
    return 8;
  }
  static ALL_PRODUCTS_NOT_RECEIVED_AT_DEPOT() {
    return 9;
  }
  static SCHEDULED_DATE_NOT_CONFIRMED() {
    return 10;
  }
  static SCHEDULED_DATE_DECLINED() {
    return 11;
  }
  static SCHEDULED_DATE_CONFIRMED() {
    return 12;
  }
  static SUCCESSFULLY_COMPLETED() {
    return 13;
  }
  static ORDER_CANCELLED() {
    return 14;
  }
  static ORDER_UNCANCELLED() {
    return 15;
  }
  static MARKED_CANCELLED() {
    return 16;
  }
  static SCHEDULED_DATE_SLOT_CONFIRMED() {
    return 17;
  }
  static ORDER_ON_HOLD() {
    return 18;
  }
  static MARKED_CREATED() {
    return 19;
  }
  static EXCEPTION() {
    return 20;
  }
  static MARKED_DELETED() {
    return 21;
  }
  static MARKED_CANCELLED_BOTH() {
    return 22;
  }
  static MARKED_DELETED_BOTH() {
    return 23;
  }
  static MARKED_ORDER_PRODUCT_DELETED() {
    return 24;
  }
  static MARKED_FULFILLED() {
    return 25;
  }
  static MARKED_ORDER_UPDATE() {
    return 26;
  }

  static CheckOrderStatusIsValidForCreate(orders) {
    let ordersNumbers = [];
    for (var key in orders) {
      let isValid = [OrderStatusEnum.NEW(), OrderStatusEnum.DELETED()].includes(
        parseInt(orders[key].status_id)
      );

      if (!isValid) {
        ordersNumbers.push(orders[key].order_number);
      }
    }

    // if (ordersNumbers.length == 0 && !Boolean(APIService.GetStoreConfig("create_orders")) && !Boolean(APIService.GetStoreConfig("batch_orders_auto")))
    if (ordersNumbers.length == 0) return { success: true, message: null };
    else {
      // if (isSettingMessageFirst) {

      //   if (Boolean(APIService.GetStoreConfig("create_orders"))) {
      //     return "'AUTO DISPATCH ORDERS TO BJS' setting is on.";
      //   }
      //   if (Boolean(APIService.GetStoreConfig("batch_orders_auto"))) {
      //     return "'BATCH ORDERS AUTO' setting is on.";
      //   }
      // }
      // let ordersNumbersOneToFour = ordersNumbers.slice(0, 4);

      // ordersNumbersOneToFour = "'" + ordersNumbersOneToFour.join("', '") + "'";
      // return (ordersNumbers.length == 1 ? "Order" : "Orders") + " already Dispatched : " + ordersNumbersOneToFour + (ordersNumbers.length > 4 ? ("and " + (ordersNumbers.length - 4) + " more") : "") + ".";

      if (ordersNumbers.length == orders.length) {
        return { success: false, message: null };
      }
      return {
        success: true,
        message: "Please de-select already dispatched orders to continue.",
      };
    }
  }
  static CheckOrderStatusIsValidForRelease(orders) {
    let ordersNumbers = [];
    for (var key in orders) {
      let isValid = [
        OrderStatusEnum.NEW(),
        OrderStatusEnum.ORDER_CREATED(),
        OrderStatusEnum.DELETED(),
      ].includes(parseInt(orders[key].status_id));

      if (!isValid) {
        ordersNumbers.push(orders[key].order_number);
      }
    }

    if (
      ordersNumbers.length == 0 &&
      !APIService.GetStoreConfig("released_orders")
    )
      return { success: true, message: null };
    else {
      // if (isSettingMessageFirst) {
      //   if (Boolean(APIService.GetStoreConfig("released_orders"))) {
      //     return "'AUTO RELEASE ORDERS TO BJS' setting is on.";
      //   }
      // }

      // let ordersNumbersOneToFour = ordersNumbers.slice(0, 4);

      // ordersNumbersOneToFour = "'" + ordersNumbersOneToFour.join("', '") + "'";
      // return (ordersNumbers.length == 1 ? "Order" : "Orders") + " already released : " + ordersNumbersOneToFour + (ordersNumbers.length > 4 ? ("and " + (ordersNumbers.length - 4) + " more") : "") + ".";

      if (ordersNumbers.length == orders.length) {
        return { success: false, message: null };
      }
      return {
        success: true,
        message:
          "Can&apos;t Release Orders without consignment number. Please de-select them and proceed.",
      };
    }
  }
  static CheckOrderStatusIsValidForGenerateLabels(orders) {
    let ordersNumbers = [];
    for (var key in orders) {
      let isValid =
        [
          OrderStatusEnum.ORDER_CREATED(),
          OrderStatusEnum.MARKED_RELEASED(),
          OrderStatusEnum.ORDER_RELEASED(),
          OrderStatusEnum.ORDER_UN_RELEASED(),
          OrderStatusEnum.PRODUCT_RECEIVED_INTO_WAREHOUSE(),
          OrderStatusEnum.ALL_DELIVERY_PRODUCTS_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.ALL_PRODUCTS_NOT_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.SCHEDULED_DATE_NOT_CONFIRMED(),
          OrderStatusEnum.SCHEDULED_DATE_DECLINED(),
          OrderStatusEnum.SCHEDULED_DATE_CONFIRMED(),
          OrderStatusEnum.ORDER_UNCANCELLED(),
          OrderStatusEnum.SCHEDULED_DATE_SLOT_CONFIRMED(),
          OrderStatusEnum.ORDER_ON_HOLD(),
          OrderStatusEnum.EXCEPTION(),
        ].includes(parseInt(orders[key].status_id)) &&
        orders[key].consignment_no;

      if (
        [OrderStatusEnum.MARKED_RELEASED()].includes(
          parseInt(orders[key].status_id)
        )
      ) {
        isValid =
          isValid &&
          orders[key].consignment_no &&
          orders[key].exception_message != null;
      }

      if (!isValid) {
        ordersNumbers.push(orders[key].order_number);
      }
    }

    if (ordersNumbers.length == 0) return { success: true, message: null };
    else {
      if (ordersNumbers.length == orders.length) {
        // let ordersNumbersOneToFour = ordersNumbers.slice(0, 4);

        // ordersNumbersOneToFour = "'" + ordersNumbersOneToFour.join("', '") + "'";
        // return (ordersNumbers.length == 1 ? "Order" : "Orders") + " not created to BJS : " + ordersNumbersOneToFour + (ordersNumbers.length > 4 ? ("and " + (ordersNumbers.length - 4) + " more") : "") + ".";
        return { success: false, message: null };
      }
      return {
        success: true,
        message:
          "Label cannot be generated for orders without consignment number.",
      };
    }
  }
  static CheckOrderStatusIsValidForCancelOrder(orders) {
    let ordersNumbers = [];
    for (var key in orders) {
      let isValid =
        [
          OrderStatusEnum.ORDER_CREATED(),
          OrderStatusEnum.ORDER_RELEASED(),
          OrderStatusEnum.ORDER_UN_RELEASED(),
          OrderStatusEnum.PRODUCT_RECEIVED_INTO_WAREHOUSE(),
          OrderStatusEnum.ALL_DELIVERY_PRODUCTS_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.ALL_PRODUCTS_NOT_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.SCHEDULED_DATE_NOT_CONFIRMED(),
          OrderStatusEnum.SCHEDULED_DATE_DECLINED(),
          OrderStatusEnum.SCHEDULED_DATE_CONFIRMED(),
          OrderStatusEnum.ORDER_UNCANCELLED(),
          OrderStatusEnum.SCHEDULED_DATE_SLOT_CONFIRMED(),
          OrderStatusEnum.ORDER_ON_HOLD(),
          OrderStatusEnum.EXCEPTION(),
        ].includes(parseInt(orders[key].status_id)) &&
        orders[key].consignment_no;

      if (
        [OrderStatusEnum.MARKED_RELEASED()].includes(
          parseInt(orders[key].status_id)
        )
      ) {
        isValid =
          isValid &&
          orders[key].consignment_no &&
          orders[key].exception_message != null;
      }
      if (!isValid) {
        ordersNumbers.push(orders[key].order_number);
      }
    }

    if (ordersNumbers.length == 0) return { success: true, message: null };
    else {
      if (ordersNumbers.length == orders.length) {
        return { success: false, message: null };
      }
      return {
        success: true,
        message:
          "Only dispatched orders can be cancelled, please de-select new orders to continue.",
      };

      // let ordersNumbersOneToFour = ordersNumbers.slice(0, 4);

      // ordersNumbersOneToFour = "'" + ordersNumbersOneToFour.join("', '") + "'";
      // return (ordersNumbers.length == 1 ? "Order" : "Orders") + " not created to BJS : " + ordersNumbersOneToFour + (ordersNumbers.length > 4 ? ("and " + (ordersNumbers.length - 4) + " more") : "") + ".";
    }
  }
  static CheckOrderStatusIsValidForDeleteOrder(orders) {
    let ordersNumbers = [];
    for (var key in orders) {
      let isValid =
        ([
          OrderStatusEnum.MARKED_RELEASED(),
          OrderStatusEnum.MARKED_CREATED(),
        ].includes(parseInt(orders[key].status_id)) &&
          !orders[key].consignment_no &&
          orders[key].exception_message != null) ||
        OrderStatusEnum.NEW() == orders[key].status_id;
      if (!isValid) {
        ordersNumbers.push(orders[key].order_number);
      }
    }

    if (ordersNumbers.length == 0) return { success: true, message: null };
    else {
      if (ordersNumbers.length == orders.length) {
        return { success: false, message: null };
      }
      return {
        success: true,
        message:
          "Unable to delete the order as the it has already been released to BJS",
      };

      // let ordersNumbersOneToFour = ordersNumbers.slice(0, 4);

      // ordersNumbersOneToFour = "'" + ordersNumbersOneToFour.join("', '") + "'";
      // return (ordersNumbers.length == 1 ? "Order" : "Orders") + " created to BJS :<br /> " + ordersNumbersOneToFour + (ordersNumbers.length > 4 ? ("and " + (ordersNumbers.length - 4) + " more") : "") + ".";
    }
  }
  static CheckOrderStatusIsValidForAddNotes(orders) {
    let isValid = true;
    for (var key in orders) {
      isValid =
        isValid &&
        [
          OrderStatusEnum.NEW(),
          OrderStatusEnum.DELETED(),
          OrderStatusEnum.ORDER_CREATED(),
          OrderStatusEnum.MARKED_RELEASED(),
          OrderStatusEnum.ORDER_RELEASED(),
          OrderStatusEnum.ORDER_UN_RELEASED(),
          OrderStatusEnum.PRODUCT_RECEIVED_INTO_WAREHOUSE(),
          OrderStatusEnum.ALL_DELIVERY_PRODUCTS_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.ALL_PRODUCTS_NOT_RECEIVED_AT_DEPOT(),
          OrderStatusEnum.SCHEDULED_DATE_NOT_CONFIRMED(),
          OrderStatusEnum.SCHEDULED_DATE_DECLINED(),
          OrderStatusEnum.SCHEDULED_DATE_CONFIRMED(),
          OrderStatusEnum.ORDER_UNCANCELLED(),
          OrderStatusEnum.SCHEDULED_DATE_SLOT_CONFIRMED(),
          OrderStatusEnum.ORDER_ON_HOLD(),
          OrderStatusEnum.MARKED_CREATED(),
          OrderStatusEnum.EXCEPTION(),
        ].includes(parseInt(orders[key].status_id)) &&
        orders[key].consignment_no;

      if (
        [OrderStatusEnum.MARKED_RELEASED()].includes(
          parseInt(orders[key].status_id)
        )
      ) {
        isValid =
          isValid &&
          orders[key].consignment_no &&
          orders[key].exception_message != null;
      }
    }
    return { success: Boolean(isValid), message: null };
  }
  static CheckOrderStatusIsValidForRetry(orders) {
    let isValid = true;
    for (var key in orders) {
      isValid =
        isValid &&
        Boolean(orders[key].exception_message) &&
        !(orders[key].status_id == OrderStatusEnum.ORDER_ON_HOLD());
    }
    return { success: Boolean(isValid), message: null };
  }
  static CheckOrderStatusIsValidForEditable(orders) {
    let isValid = true;
    for (var key in orders) {
      isValid =
        isValid &&
        ![
          OrderStatusEnum.DELETED(),
          OrderStatusEnum.ORDER_CANCELLED(),
          OrderStatusEnum.SUCCESSFULLY_COMPLETED(),
        ].includes(parseInt(orders[key].status_id));
    }
    // return { success: Boolean(isValid), message: null };
    return Boolean(isValid);
  }

  static CheckOrderStatusIsCheckBoxOperation(orders) {
    let isValid = true;
    for (var key in orders) {
      isValid =
        isValid &&
        ![
          OrderStatusEnum.ORDER_CANCELLED(),
          OrderStatusEnum.SUCCESSFULLY_COMPLETED(),
        ].includes(parseInt(orders[key].status_id));

      isValid =
        isValid &&
        !(
          [
            OrderStatusEnum.MARKED_RELEASED(),
            OrderStatusEnum.MARKED_CANCELLED(),
            OrderStatusEnum.MARKED_CANCELLED_BOTH(),
            OrderStatusEnum.MARKED_CREATED(),
            OrderStatusEnum.MARKED_DELETED(),
            OrderStatusEnum.MARKED_DELETED_BOTH(),
            OrderStatusEnum.MARKED_ORDER_PRODUCT_DELETED(),
            OrderStatusEnum.MARKED_FULFILLED(),
            OrderStatusEnum.MARKED_ORDER_UPDATE(),
          ].includes(parseInt(orders[key].status_id)) &&
          orders[key].exception_message == null
        );
    }
    return { success: Boolean(isValid), message: null };
    // return Boolean(isValid);
  }
}
