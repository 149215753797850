import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import APIResponsePopupHelper from "./APIResponsePopupHelper.jsx";
class ApiResponseError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: true,
    };
  }

  // Call when refresh the page
  componentDidMount() {
    // confirmAlert({
    //   title: <ErrorIcon />,
    //   message: this.props.response.message,
    //   buttons: [
    //     {
    //       label: "Close",
    //       onClick: () => {
    //         if (this.props.onClick) {
    //           this.props.onClick();
    //         }
    //       },
    //     },
    //   ],
    //   overlayClassName: "error-handle-custom-class"
    // });
  }

  render() {
    return (
      this.state.isPopup && (
        <APIResponsePopupHelper
          failureClose={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
            this.setState({ isPopup: false });
            // alert();
          }}
          result={{ success: false, message: this.props.response.message }}
        />
      )
    );
  }
}

export default ApiResponseError;
