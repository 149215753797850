import * as React from "react";
import Skeleton from "@mui/material/Skeleton";

import {
  Button,
  // FormLabel,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AppSettings from "app-settings";
import { Component } from "react";
import { ReactComponent as CheckIcon } from "../../assets/img/generalIcons_Check.svg";
import { ReactComponent as AutoAssembly } from "../../assets/img/settingsIcon_Assembly.svg";
import { ReactComponent as ContactUs } from "../../assets/img/contact.svg";
import { ReactComponent as AutoDispatch } from "../../assets/img/settingsIcon_Auto.svg";
import { ReactComponent as Stock } from "../../assets/img/warehouse.svg";
import { ReactComponent as BatchProcessingIcon } from "../../assets/img/settingsIcon_BatchProcessing.svg";
import { ReactComponent as LinkIcon } from "../../assets/img/settingsIcon_Connected.svg";
import { ReactComponent as Visibility } from "../../assets/img/settingsIcon_Hide.svg";
import { ReactComponent as VisibilityOff } from "../../assets/img/settingsIcon_Show.svg";
import APIService from "../../services/APIService.js";
import Help from "../Help/Help.jsx";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import APIResponsePopupHelper from "../helper/APIResponsePopupHelper.jsx";
import LoadingHelper from "../helper/LoadingHelper.jsx";
import SwitchHelper from "../helper/SwitchHelper.jsx";
import "./settingsPage.scss";

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    props.getHelp(Help.SettingsPage);

    this.state = {
      errorAlert: null,
      pageLoading: true,
      contactUsPannel: false,
      isExist: false,
      Settings: {
        bjs_staging_account_number: "",
        bjs_staging_api_key: "",
        bjs_prod_account_number: "",
        bjs_prod_api_key: "",
        self_label: false,
        released_orders: false,
        create_orders: false,
        assembly_allowed: false,
        hold_stock_with_bjs: false,
        is_live_api: false,
        batch_orders_auto: false,
        batch_orders_manual: false,
        slot_10am: false,
        slot_12pm: false,
        slot_1pm: false,
        update_order_status_shopify: false,
        order_payment_status: null,
        fulfillment_status: null,
        order_status: null,
      },
      APIResponsePopupMessage: "",
      progress: false,
      showPass: {
        stagingAccountNumber: false,
        stagingApiKey: false,
        productionAccountNumber: false,
        productionApiKey: false,
      },
      SnackbarHelperMessage: null,
      SnackbarHelperType: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    // this.linkDisabledFunction = props.linkDisabledFunction.bind();
  }

  componentDidMount() {
    APIService.GET(AppSettings.GetSettingsAPI)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.success && (response.success = true)) {
          this.setState({
            pageLoading: false,
          });
          if (response.result.id != null) {
            this.setState({ isExist: true });
            localStorage.setItem(
              "store_config",
              JSON.stringify(response.result)
            );
          }

          // console.disableYellowBox = true;

          this.setState({ Settings: response.result });
        } else {
          this.setState({
            errorAlert: <ApiResponseError response={response} />,
            pageLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          errorAlert: (
            <ApiResponseError
              response={{
                message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
              }}
            />
          ),
          pageLoading: false,
        });
      });
  }

  hideAlert() {
    // this.setState({
    //   APIResponsePopupMessage: null
    // });
  }

  handleSubmit(event) {
    this.setState({ progress: true });
    event.preventDefault();
    let Settings = this.state.Settings;

    (this.state.isExist == false
      ? APIService.POST(AppSettings.AddSettingsAPI, Settings)
      : APIService.PUT(AppSettings.SetSettingsAPI, Settings)
    )
      .then((response) => response.json())
      .then((response) => {
        this.setState({ progress: false });
        if (response.success == true) {
          this.setState({
            APIResponsePopupMessage: response,
            isExist: true,
            Settings: response.result,
          });
          // Enable Left Side Menu
          this.props.linkDisabledFunction();
          localStorage.setItem("store_config", JSON.stringify(response.result));
          APIService.UpdateStoreConfiguredStatus(true);

          this.props.isDispatchDisabledFunction(
            response.result.batch_orders_auto ||
              response.result.batch_orders_manual
          );
          // this.props.setIsLiveApi(!response.result.is_live_api);
        } else {
          // let message = null;
          // if (response.Message) {
          //   let responseMessage = response.Message;
          //   message = responseMessage[Object.keys(responseMessage)[0]][0];
          // }
          // else {
          //   message = response.message;
          // }

          this.setState({
            APIResponsePopupMessage: response,
          });
          APIService.UpdateStoreConfiguredStatus(false);
        }
      })
      .catch(() => {
        this.setState({ progress: "" });
        this.setState({
          APIResponsePopupMessage: {
            message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
            success: false,
          },
        });
      });
    event.preventDefault();
  }

  // Script to hanlde the contactUs pannel activeness
  handleContactUs = () => {
    this.setState({ contactUsPannel: true });
  };

  render() {
    return (
      <>
        {this.state.progress && (
          <LoadingHelper executeScroll={this.props.executeScroll} />
        )}

        {this.state.APIResponsePopupMessage && (
          <APIResponsePopupHelper
            result={this.state.APIResponsePopupMessage}
            successClose={() => {
              this.setState({ APIResponsePopupMessage: false });
            }}
            failureClose={() => {
              this.setState({ APIResponsePopupMessage: false });
            }}
          />
        )}
        {this.state.errorAlert}
        <Grid container spacing={3} id="setting-page">
          <Grid item xs={12} sm={12} md={6}>
            <h1 className="pageTitle">SETTINGS </h1>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="get-api-keys">
            <div className="main">
              <Typography variant="span" gutterBottom className="">
                Don’t have your API keys?{" "}
              </Typography>
              <a href="javascript:void(0);" onClick={this.handleContactUs}>
                Talk To Us
              </a>
            </div>
          </Grid>
          {this.state.alert && (
            <Grid item xs={12} sm={12} md={12}>
              <Alert severity={this.state.alertType} id="snackbar">
                {this.state.alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} className="">
            <form onSubmit={this.handleSubmit} onFocus={this.hideAlert}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="api-key-and-number"
                >
                  <TextBox
                    isPassword={this.state.showPass.stagingAccountNumber}
                    onClickPasswordType={() => {
                      let showPass = Object.assign({}, this.state.showPass);
                      showPass.stagingAccountNumber =
                        !showPass.stagingAccountNumber;
                      this.setState({ showPass: showPass });
                    }}
                    pageLoading={this.state.pageLoading}
                    value={
                      this.state.Settings
                        ? this.state.Settings.bjs_staging_account_number
                        : ""
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      let settings = Object.assign({}, this.state.Settings);
                      settings.bjs_staging_account_number = value;
                      this.setState({ Settings: settings });
                    }}
                    title={"ACCOUNT NUMBER"}
                  />
                  <div className="center-icon">
                    <LinkIcon />
                  </div>
                  <TextBox
                    pageLoading={this.state.pageLoading}
                    isPassword={this.state.showPass.stagingApiKey}
                    onClickPasswordType={() => {
                      let showPass = Object.assign({}, this.state.showPass);
                      showPass.stagingApiKey = !showPass.stagingApiKey;
                      this.setState({ showPass: showPass });
                    }}
                    value={
                      this.state.Settings
                        ? this.state.Settings.bjs_staging_api_key
                        : ""
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      let settings = Object.assign({}, this.state.Settings);
                      settings.bjs_staging_api_key = value;
                      this.setState({ Settings: settings });
                    }}
                    title={"API KEY"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className="api-key-and-number"
                >
                  <TextBox
                    pageLoading={this.state.pageLoading}
                    isPassword={this.state.showPass.productionAccountNumber}
                    onClickPasswordType={() => {
                      let showPass = Object.assign({}, this.state.showPass);
                      showPass.productionAccountNumber =
                        !showPass.productionAccountNumber;
                      this.setState({ showPass: showPass });
                    }}
                    value={
                      this.state.Settings
                        ? this.state.Settings.bjs_prod_account_number
                        : ""
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      let settings = Object.assign({}, this.state.Settings);
                      settings.bjs_prod_account_number = value;
                      this.setState({ Settings: settings });
                    }}
                    title={"PRODUCTION ACCOUNT NUMBER"}
                  />
                  <div className="center-icon">
                    <LinkIcon />
                  </div>
                  <TextBox
                    pageLoading={this.state.pageLoading}
                    isPassword={this.state.showPass.productionApiKey}
                    onClickPasswordType={() => {
                      let showPass = Object.assign({}, this.state.showPass);
                      showPass.productionApiKey = !showPass.productionApiKey;
                      this.setState({ showPass: showPass });
                    }}
                    value={
                      this.state.Settings
                        ? this.state.Settings.bjs_prod_api_key
                        : ""
                    }
                    onChange={(event) => {
                      const { value } = event.target;
                      let settings = Object.assign({}, this.state.Settings);
                      settings.bjs_prod_api_key = value;
                      this.setState({ Settings: settings });
                    }}
                    title={"PRODUCTION API KEY"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="shopify-settings">
                  <h2>Order Settings</h2>
                </Grid>
                <Dropdown
                  pageLoading={this.state.pageLoading}
                  message={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  }
                  options={{
                    Open: "Open",
                    Archived: "Archived",
                    // Canceled: "Cancelled",
                  }}
                  title="Order Status"
                  value={
                    this.state.Settings
                      ? this.state.Settings.order_status ?? "Open"
                      : "Open"
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    let settings = Object.assign({}, this.state.Settings);
                    settings.order_status = value;
                    this.setState({ Settings: settings });
                  }}
                />

                <Dropdown
                  pageLoading={this.state.pageLoading}
                  message={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  }
                  options={{
                    Authorized: "Authorized",
                    Overdue: "Overdue",
                    Paid: "Paid",
                    "Partially refunded": "Partially refunded",
                    "Partially paid": "Partially paid",
                    "Payment pending": "Payment pending",
                    Refunded: "Refunded",
                    Unpaid: "Unpaid",
                    Voided: "Voided",
                  }}
                  title="Order Payment Status"
                  value={
                    this.state.Settings
                      ? this.state.Settings.order_payment_status ?? "Paid"
                      : "Paid"
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    let settings = Object.assign({}, this.state.Settings);
                    settings.order_payment_status = value;
                    this.setState({ Settings: settings });
                  }}
                />
                <Dropdown
                  pageLoading={this.state.pageLoading}
                  message={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  }
                  options={{
                    Fulfilled: "Fulfilled",
                    Unfulfilled: "Unfulfilled",
                    "Partially fulfilled": "Partially Fulfilled",
                    Scheduled: "Scheduled",
                    "On hold": "On Hold",
                  }}
                  title="Fulfillment Status"
                  value={
                    this.state.Settings
                      ? this.state.Settings.fulfillment_status ?? "Unfulfilled"
                      : "Unfulfilled"
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    let settings = Object.assign({}, this.state.Settings);
                    settings.fulfillment_status = value;
                    this.setState({ Settings: settings });
                  }}
                />
                {/* <Dropdown
                  pageLoading={this.state.pageLoading}
                  message={
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  }
                  options={{
                    1:"True",
                    0:"False"
                  }}
                  title="Update Order Status Shopify"
                  value={
                    this.state.Settings
                      ? this.state.Settings.update_order_status_shopify ?? 0
                      : 0
                  }
                  onChange={(event) => {
                    const { value } = event.target;
                    let settings = Object.assign({}, this.state.Settings);
                    settings.update_order_status_shopify = value;
                    this.setState({ Settings: settings });
                  }}
                /> */}
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<ContactUs />}
                  title={"Contact Us"}
                  checked={this.state.contactUsPannel}
                >
                  <div className="body">
                    You can catch us at <strong>+44 1922 324115</strong> and
                    <strong> servicedesk@bjshomedelivery.com</strong>.
                  </div>
                </SwitchPanel>
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<AutoAssembly />}
                  title={"Update Order Status Shopify"}
                  checked={
                    this.state.Settings &&
                    this.state.Settings.update_order_status_shopify
                  }
                  message={
                    "Enabling this setting will automatically update order status on Shopify as soon as it get's updated in BJS system."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    settings.update_order_status_shopify =
                      !settings.update_order_status_shopify;
                    this.setState({ Settings: settings });
                  }}
                />
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<AutoAssembly />}
                  title={"allow assembly"}
                  checked={
                    this.state.Settings && this.state.Settings.assembly_allowed
                  }
                  message={
                    "Enabling this function will send through a ‘build time’ for any products that have a build time defined and assembly enabled, within the ‘Product Management' module."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    const checked = !settings.assembly_allowed;

                    settings.assembly_allowed = checked;
                    this.setState({ Settings: settings });
                  }}
                />
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<AutoDispatch />}
                  title={"auto dispatch orders to bjs"}
                  checked={
                    this.state.Settings && this.state.Settings.create_orders
                  }
                  message={
                    "Enabling this setting will automatically create all Shopify orders on BJS systems without the need to manually dispatch."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    const checked = !settings.create_orders;
                    settings.create_orders = checked;
                    if (checked) settings.batch_orders_auto = 0;
                    if (checked) settings.batch_orders_manual = 0;

                    this.setState({ Settings: settings });
                  }}
                />
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<AutoDispatch />}
                  title={"auto release stock to bjs"}
                  checked={
                    this.state.Settings && this.state.Settings.released_orders
                  }
                  message={
                    "Enabling this setting will automatically release stock to BJS, which makes an order ‘live’ for processing within BJS systems."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    const checked = !settings.released_orders;

                    settings.released_orders = checked;
                    this.setState({ Settings: settings });
                  }}
                />
                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<BatchProcessingIcon />}
                  title={"BATCH ORDERS"}
                  checked={
                    this.state.Settings &&
                    (this.state.Settings.batch_orders_manual ||
                      this.state.Settings.batch_orders_auto)
                  }
                  message={
                    "This function allows orders to be 'batched', assigning a shared unique batch reference number to each order within a batch."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    const checked = !(
                      this.state.Settings.batch_orders_manual ||
                      this.state.Settings.batch_orders_auto
                    );
                    settings.batch_orders_manual = checked;
                    settings.batch_orders_auto = false;
                    settings.slot_10am = false;
                    settings.slot_12pm = false;
                    settings.slot_1pm = false;

                    if (checked) settings.create_orders = false;

                    this.setState({ Settings: settings });
                  }}
                >
                  {this.state.Settings &&
                    (this.state.Settings.batch_orders_manual == true ||
                      this.state.Settings.batch_orders_auto == true) && (
                      <div className="batch-orders-auto-manual">
                        <h6>
                          Please select a mode for your batch orders:
                          <span />
                        </h6>
                        <div
                          style={{ cursor: "pointer" }}
                          className={
                            "batch-order-panel " +
                            (this.state.Settings.batch_orders_manual
                              ? "active"
                              : "")
                          }
                          onClick={() => {
                            let settings = Object.assign(
                              {},
                              this.state.Settings
                            );
                            settings.batch_orders_manual = true;
                            settings.batch_orders_auto = false;
                            settings.slot_10am = false;
                            settings.slot_12pm = false;
                            settings.slot_1pm = false;

                            if (
                              settings.batch_orders_manual ||
                              settings.batch_orders_auto
                            )
                              settings.create_orders = false;

                            this.setState({ Settings: settings });
                          }}
                        >
                          <div className="check-box-icon">
                            <span className="check-icon">
                              <CheckIcon />
                            </span>
                          </div>
                          <div className="body">
                            <h4>MANUAL MODE</h4>
                            <p>
                              You will need to create a batch manually from
                              dispatches.
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "batch-order-panel " +
                            (this.state.Settings.batch_orders_auto
                              ? "active"
                              : "")
                          }
                        >
                          <div
                            className="check-box-icon"
                            onClick={() => {
                              let settings = Object.assign(
                                {},
                                this.state.Settings
                              );
                              settings.batch_orders_auto = true;
                              settings.batch_orders_manual = false;
                              settings.slot_10am = true;
                              settings.slot_12pm = true;
                              settings.slot_1pm = true;

                              if (
                                settings.batch_orders_manual ||
                                settings.batch_orders_auto
                              )
                                settings.create_orders = false;

                              this.setState({ Settings: settings });
                            }}
                          >
                            <span className="check-icon">
                              <CheckIcon />
                            </span>
                          </div>
                          <div className="body">
                            <div
                              onClick={() => {
                                let settings = Object.assign(
                                  {},
                                  this.state.Settings
                                );
                                settings.batch_orders_auto = true;
                                settings.batch_orders_manual = false;
                                settings.slot_10am = true;
                                settings.slot_12pm = true;
                                settings.slot_1pm = true;

                                if (
                                  settings.batch_orders_manual ||
                                  settings.batch_orders_auto
                                )
                                  settings.create_orders = false;

                                this.setState({ Settings: settings });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <h4>AUTO MODE</h4>
                              <p>
                                Schedule a batch of orders to be dispatched to
                                BJS at a specified time interval
                                {!this.state.Settings.batch_orders_auto
                                  ? ", e.g. 10am, 12pm, 1pm."
                                  : "."}
                              </p>
                            </div>
                            {this.state.Settings.batch_orders_auto == true && (
                              <>
                                <p className="send-batch-order">
                                  Please select a scheduled time to send your
                                  batch orders:{" "}
                                </p>
                                <div
                                  className="slot"
                                  onClick={() => {
                                    let settings = Object.assign(
                                      {},
                                      this.state.Settings
                                    );
                                    settings.slot_10am = true;
                                    settings.slot_12pm = true;
                                    settings.slot_1pm = true;
                                    this.setState({ Settings: settings });
                                  }}
                                >
                                  <span className="radio-button">
                                    {this.state.Settings.slot_10am == true && (
                                      <span />
                                    )}
                                  </span>
                                  <span>10am</span>
                                </div>
                                <div
                                  className="slot"
                                  onClick={() => {
                                    let settings = Object.assign(
                                      {},
                                      this.state.Settings
                                    );
                                    settings.slot_10am = true;
                                    settings.slot_12pm = true;
                                    settings.slot_1pm = true;

                                    this.setState({ Settings: settings });
                                  }}
                                >
                                  <span className="radio-button">
                                    {this.state.Settings.slot_12pm == true && (
                                      <span />
                                    )}
                                  </span>
                                  <span>12pm</span>
                                </div>
                                <div
                                  className="slot"
                                  onClick={() => {
                                    let settings = Object.assign(
                                      {},
                                      this.state.Settings
                                    );
                                    settings.slot_10am = true;
                                    settings.slot_12pm = true;
                                    settings.slot_1pm = true;
                                    this.setState({ Settings: settings });
                                  }}
                                >
                                  <span className="radio-button">
                                    {this.state.Settings.slot_1pm == true && (
                                      <span />
                                    )}
                                  </span>
                                  <span>1pm</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </SwitchPanel>

                <SwitchPanel
                  pageLoading={this.state.pageLoading}
                  icon={<Stock />}
                  title={"Hold stock with BJS"}
                  checked={
                    this.state.Settings &&
                    this.state.Settings.hold_stock_with_bjs
                  }
                  message={
                    "Enabling this setting will enable stock handling with BJS Warehouse."
                  }
                  onChange={() => {
                    let settings = Object.assign({}, this.state.Settings);
                    const checked = !settings.hold_stock_with_bjs;

                    settings.hold_stock_with_bjs = checked;
                    this.setState({ Settings: settings });
                  }}
                />
                <Grid item xs={12} sm={12} md={12} className="submit-button">
                  <Button
                    type="submit"
                    variant="contained"
                    // endIcon={this.state.progress}
                  >
                    SAVE SETTINGS
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
    );
  }

  // _Change(event) {
  //   //update the value here
  //   const { value } = event.target;
  // }
}

export default SettingsPage;

class TextBox extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="text-box">
        <Typography className="title" variant="subtitle2" component="h6">
          {this.props.pageLoading && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={20}
              width={"50%"}
            />
          )}
          {!this.props.pageLoading && <>{this.props.title}</>}
        </Typography>
        {this.props.pageLoading && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={40}
            width={"100%"}
          />
        )}
        {!this.props.pageLoading && (
          <OutlinedInput
            type={!this.props.isPassword ? "password" : "text"}
            className="input-box"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={this.props.onClickPasswordType}>
                  {!this.props.isPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
            required
            variant="outlined"
            size="small"
            value={this.props.value}
            onChange={this.props.onChange}
          />
        )}
      </div>
    );
  }
}

class Dropdown extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={4} className="dropdown">
        <Typography variant="subtitle2" component="h6" className="title">
          {this.props.pageLoading && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={15}
              width={"50%"}
            />
          )}
          {!this.props.pageLoading && <>{this.props.title}</>}
        </Typography>
        <span className="line">
          <span />
        </span>
        <p className="message">{this.props.message}</p>
        {this.props.pageLoading && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={40}
            width={"100%"}
          />
        )}
        {!this.props.pageLoading && (
          <FormControl fullWidth size="small">
            <Select
              className="p-10-14"
              fullWidth
              required
              variant="outlined"
              displayEmpty
              value={this.props.value}
              onChange={this.props.onChange}
            >
              <MenuItem disabled> {this.props.title}</MenuItem>
              {Object.keys(this.props.options).map((key, index) => (
                <MenuItem key={key + index} value={key}>
                  {this.props.options[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    );
  }
}

class SwitchPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid item xs={12} sm={12} md={6} className="">
        {this.props.pageLoading && (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={150}
            width={"100%"}
          />
        )}
        {!this.props.pageLoading && (
          <div
            className={
              "switch-box-panel " + (this.props.checked ? "active" : "")
            }
          >
            <div className="header" onClick={this.props.onChange}>
              {this.props.icon}
              <h2>{this.props.title}</h2>
              <SwitchHelper
                checked={this.props.checked}
                onChange={this.props.onChange}
                color="primary"
              />
            </div>
            <p
              className={`${this.props.message ? "body" : ""}`}
              onClick={this.props.onChange}
            >
              {this.props.message}
            </p>
            {this.props.children}
          </div>
        )}
      </Grid>
    );
  }
}
