import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@material-ui/core/";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";

import "@lottiefiles/lottie-player";

export default function LoadingHelper(props) {
  // document.getElementById("back-to-top").click();
  return (
    <Dialog className={"page-loader"} open={true}>
      {props.onClose && (
        <IconButton className="close-icon" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      )}
      <LoadingOnPopupHelper loaderMessage={props.loaderMessage} />
    </Dialog>
  );
}
export function LoadingOnPopupHelper(props) {
  return (
    <div className={"custom-loader " + props.className ?? {}}>
      <lottie-player
        src={"/dist/manifest.json"}
        background="transparent"
        speed="1"
        style={{ width: "124px", height: "124px" }}
        loop=""
        autoplay=""
      ></lottie-player>

      <h1>the wheels are in motion</h1>
      <p>
        {props.loaderMessage
          ? props.loaderMessage
          : "We're saving your changes, hang tight!"}
      </p>
    </div>
  );
}
