import * as React from "react";
import { ReactComponent as CheckIcon } from "../../assets/img/generalIcons_Check.svg";
import "./../layouts/layout1.scss";

export default function CheckBoxHelper(props) {
  // const [isSearchBoxClicked, setIsSearchBoxClicked] = React.useState(false);
  const handleClick = () => {
    props.onChange(null, !props.checked);
    // setIsSearchBoxClicked(!isSearchBoxClicked);
  };
  return (
    <button
      id={props.id ?? ""}
      className={
        "check-box " + props.className ??
        "" + " " + (props.checked ? "active" : "")
      }
      onClick={handleClick}
    >
      {props.checked && <CheckIcon className="check" onClick={handleClick} />}
    </button>
  );
}
