import * as React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// import { Stack } from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarHelper(props) {
  // const [open, setOpen] = React.useState(true);
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      resumeHideDuration="100000000"
      open={true}
    >
      <Alert severity={props.type} id="snackbar">
        {props.message}
      </Alert>
    </Snackbar>
  );
}
