import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Skeleton from "@mui/material/Skeleton";
import AppSettings from "app-settings";
import React, { Component } from "react";
import APIService from "../../services/APIService.js";
import Help from "../Help/Help.jsx";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import NoResultHelper from "../helper/NoResultHelper.jsx";
import Pagination from "../helper/PaginationHelper.jsx";
import SearchBarHelper from "../helper/SearchBarHelper.jsx";
import DateConvertor from "./../helper/core/DateConvertor.jsx";
import "./DispatchesPage.scss";
import DispatchOrder from "./DispatchOrder.jsx";

const { innerHeight: window_height } = window;
const dataGridHeight = window_height - 356;
const styles = () => ({
  customTableContainer: {
    overflowX: "initial",
    minHeight: dataGridHeight + "px",
    // overflow: "auto",
  },
});

class DispatchesPage extends Component {
  constructor(props) {
    super(props);
    props.getHelp(Help.DispatchesPage);

    this.state = {
      errorAlert: null,
      loading: true,
      open: false,
      filtration: {
        start: 0,
        limit: 30,
        isOrderProduct: "false",
        orderByKey: "created_at",
        order: "DESC",
        searchKey: "",
        sorting: "Created-Date-DESC",
        searchValue: "",
        searchValueTypedOnly: "",
        status: 0,
      },
      isQuickFilters: false,
      total_record: 0,
      rows: [],
    };
    this.ApiRequest = this.ApiRequest.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.StartLoader = this.StartLoader.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.SearchBox = this.SearchBox.bind(this);
  }
  SearchBox = () => {
    return (
      <SearchBarHelper
        onClickSearchButton={this.inputSearch}
        value={this.state.filtration.searchValueTypedOnly}
        placeholder={"Search for a dispatch reference, order number..."}
        onChange={(event) => {
          const { value } = event.target;
          let filtration = this.state.filtration;
          filtration.searchValueTypedOnly = value;
          this.setState({ filtration: filtration, errorAlert: null });
        }}
        onSubmit={() => {
          let filtration = this.state.filtration;
          filtration.searchValue = filtration.searchValueTypedOnly;
          filtration.start = 0;

          this.setState({ filtration: filtration, errorAlert: null });
          this.StartLoader();

          this.ApiRequest();
        }}
      />
    );
  };
  handlePageSizeChange = (params) => {
    let filtration = this.state.filtration;
    filtration.start = 0;
    filtration.limit = params.target.value;
    this.setState({ filtration: filtration, errorAlert: null });

    this.StartLoader();
    this.ApiRequest();
  };
  // Call ApiRequest Datatable Load
  ApiRequest = (url = "") => {
    url = `?start=${this.state.filtration.start}&limit=${this.state.filtration.limit}&orderByKey=${this.state.filtration.orderByKey}&order=${this.state.filtration.order}&searchValue=${this.state.filtration.searchValue}`;
    (async () => {
      APIService.GET(AppSettings.GetDispatchAPI + url)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              loading: false,
              rows: response.result.data,
              total_record: response.result.total_record,
            });
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };

  componentDidMount() {
    this.ApiRequest();
  }

  // On Page change
  handlePageChange = (start) => {
    let filtration = this.state.filtration;
    filtration.start = start;
    this.setState({ filtration: filtration, errorAlert: null });
    // this.CheckboxSelection(this.state.filtration.checkedProduct);

    this.StartLoader();
    this.ApiRequest();
  };

  // Start Loader And Empty Table
  StartLoader = (is_empty_datatable = true) => {
    if (is_empty_datatable) this.setState({ rows: [], loading: true });
    else this.setState({ loading: true });
  };
  // getPagination = () => {
  //   if (this.state.total_record % this.state.filtration.limit == 0)
  //     return this.state.total_record / this.state.filtration.limit;
  //   else return this.state.total_record / this.state.filtration.limit + 1;
  // };

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.errorAlert}
        <Grid container spacing={3} id="dispatch-page">
          <Grid item xs={12} sm={12} md={2} className="page-title-grid">
            <h1 className="pageTitle">DISPATCHES</h1>
            <div className="search-box-mobile">{this.SearchBox()}</div>
          </Grid>
          <Grid item md={6} className={"d-none-xs"}></Grid>
          <Grid item xs={12} sm={12} md={4} className="search-box-grid">
            {this.SearchBox()}
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className="">
              <Typography className="record-count">
                {this.state.total_record}{" "}
                {this.state.total_record < 1 ? "Result" : "Results"}
              </Typography>

              {this.state.filtration.searchValue != "" && (
                <>
                  <a
                    color="primary"
                    className={"btn-clear"}
                    onClick={() => {
                      let filtration = this.state.filtration;

                      filtration.searchValue = "";
                      filtration.searchValueTypedOnly = "";
                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.ApiRequest();
                    }}
                  >
                    Clear Search
                  </a>
                  <span className={"btn-search-value"}>
                    Searched Key - {this.state.filtration.searchValue}
                  </span>
                </>
              )}
            </Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
          <Grid item md={12}>
            <TableContainer
              classes={{ root: classes.customTableContainer }}
              className="product-table"
            >
              <Table stickyHeader>
                <TableHead className="">
                  <TableRow>
                    <TableCell>DISPATCH REFERENCE</TableCell>
                    <TableCell>CREATOR</TableCell>
                    <TableCell>UPDATED</TableCell>
                    <TableCell>
                      ORDERS IN DISPATCH
                      <Button
                        onClick={() => {
                          this.StartLoader();
                          this.setState({ errorAlert: null, loading: true });
                          this.ApiRequest();
                        }}
                      >
                        <AutorenewIcon
                          style={{
                            color: "white",
                            justifyContent: "space-around",
                          }}
                          className={
                            this.state.loading === true ? " rotating " : "  "
                          }
                        />
                        {/* <span>{this.state.loading === true ? " Refreshing Data" : " Refresh "}</span> */}
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.loading &&
                    this.state.rows.length > 0 &&
                    this.state.rows.map((row, index) => (
                      <React.Fragment key={row.id}>
                        <TableRow
                          key={row.id}
                          className="Dispatch-Page-row curser-pointer"
                          onClick={() => {
                            let stateRows = this.state.rows;
                            let previousValue = stateRows[index].history;
                            stateRows.map(function (x) {
                              x.history = false;
                              return x;
                            });
                            if (typeof stateRows[index].orders == "undefined") {
                              stateRows[index].orders = (
                                <DispatchOrder dispatchId={row.id} />
                              );
                            }
                            this.setState({ rows: stateRows });

                            stateRows[index].history = !previousValue;
                            this.setState({ rows: stateRows });
                          }}
                        >
                          <TableCell className="dispatch-reference Dispatch-Page-row-td">
                            <img src={"images/truck.png"} />
                            <span data-testid={"batch-refrence-" + index}>
                              {row.batch_ref}
                            </span>
                          </TableCell>
                          <TableCell className="Dispatch-Page-row-td">
                            {row.creator == 0
                              ? "SYSTEM"
                              : APIService.GetSelectedStoreName()}
                          </TableCell>
                          <TableCell className="Dispatch-Page-row-td">
                            {DateConvertor(row.created_at)}
                          </TableCell>
                          <TableCell
                            data-testid={"show-order-" + index}
                            className="curser-pointer Dispatch-Page-row-td"
                          >
                            <b
                              className="mr-10"
                              data-dispatchId={row.id}
                              data-testid={"total-Orders-" + index}
                            >
                              {row.order_ids.length}
                            </b>{" "}
                            (
                            <u>{row.history ? "Hide orders" : "Show orders"}</u>
                            {") "}
                            <IconButton aria-label="expand row" size="small">
                              {row.history ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {this.state.rows[index].history && (
                          <TableRow
                            key={row.id + "_" + row.id}
                            className="Dispatch-Page-row"
                          >
                            <TableCell colSpan={5}>
                              {this.state.rows[index].orders}
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  {this.state.loading && (
                    <>
                      {Array.from(new Array(10)).map((val, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={40}
                              width={"100%"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {!this.state.loading && this.state.rows.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <NoResultHelper />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className=""></Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(DispatchesPage);
