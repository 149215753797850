import React from "react";

function DateConvertor(date, dateFormat = 0) {
  if (date != null && date != "") {
    date = new Date(date);
    date = (
      <span className="line-height-12">
        {(date.getDate() < 10 ? "0" : "") +
          date.getDate() +
          "/" +
          (date.getMonth() < 9 ? "0" : "") +
          (1 + date.getMonth()) +
          "/" +
          date.getFullYear()}
        {dateFormat == 0 && "-"}
        {dateFormat == 0 &&
          (date.getHours() < 10 ? "0" : "") +
            date.getHours() +
            ":" +
            (date.getMinutes() < 10 ? "0" : "") +
            date.getMinutes()}
      </span>
    );
    return date;
  } else return "N/A";
}
export default DateConvertor;
