import React, { Component } from "react";
import "./ErrorPage.scss";
import { Box, Typography } from "@material-ui/core/";
import ErrorIcon from "@material-ui/icons/Error";

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      message: null,
    };

    this.CheckError = this.CheckError.bind(this);
  }
  componentDidMount() {
    this.CheckError(this.props.history.location.pathname);
  }
  CheckError = (code) => {
    switch (code) {
      case "/error/401": {
        this.setState({
          code: 401,
          message:
            "Unauthorized. Please click on BJS app in Shopify to login again.",
        });
        break;
      }
      case "/error/400": {
        this.setState({
          code: 400,
          message: "Bad Request",
        });
        break;
      }
      default: {
        this.setState({
          code: 404,
          message: "Page not Found",
        });
        break;
      }
    }
  };

  render() {
    return (
      <Box p={5}>
        <Typography variant="h1" component="h2" gutterBottom>
          <ErrorIcon className="ErrorClass" />
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Error {this.state.code}
        </Typography>
        <Typography variant="p" gutterBottom>
          {this.state.message}
        </Typography>
      </Box>
    );
  }
}

export default ErrorPage;
