export default class OrderTabEnum {
  static ALL() {
    return "";
  }
  static PENDING() {
    return 1;
  }
  static QUEUED() {
    return 2;
  }
  static ACTIVE() {
    return 3;
  }
  static COMPLETED() {
    return 4;
  }
  static CANCELLED() {
    return 5;
  }
  static EXCEPTION() {
    return 6;
  }
}
