import React, { Component } from "react";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { withStyles } from "@material-ui/core";

// let mapboxHeight = 300;
// if (document.getElementById("mapBox-id"))
// mapboxHeight = document.getElementById("mapBox-id").offsetWidth;
const styles = () => ({
  mapContainer: {
    height: "230px", //mapboxHeight -  + "px",
  },
});

class MapBoxHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: props.detail.longitude,
      lat: props.detail.latitude,
      zoom: 10,
      apiResponse: props.detail,
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    document.getElementById("mapBox-id").style.height =
      document.getElementById("mapBox-id").offsetWidth + "px!important";
    const { lng, lat, zoom } = this.state;
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
    map.on("load", () => {
      // Add an image to use as a custom marker
      map.loadImage("marker.png", (error, image) => {
        if (error) throw error;
        map.addImage("custom-marker", image);
        // Add a GeoJSON source with 2 points
        map.addSource("points", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                // feature for Mapbox DC
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [lng, lat],
                },
                properties: {
                  title:
                    this.state.apiResponse.addressline1 != ""
                      ? this.state.apiResponse.addressline1 + ", "
                      : "" + this.state.apiResponse.addressline2 != ""
                      ? this.state.apiResponse.addressline2 + ", "
                      : "" + this.state.apiResponse.addressline3 != ""
                      ? this.state.apiResponse.addressline3 + ", "
                      : "" + this.state.apiResponse.city != ""
                      ? this.state.apiResponse.city + ", "
                      : "" + this.state.apiResponse.state != ""
                      ? this.state.apiResponse.state + ", "
                      : "" + this.state.apiResponse.zipcode != ""
                      ? this.state.apiResponse.zipcode + ", "
                      : "" + this.state.apiResponse.country != ""
                      ? this.state.apiResponse.country
                      : "",
                },
              },
            ],
          },
        });
        map.addControl(new mapboxgl.NavigationControl());

        // Add a symbol layer
        map.addLayer({
          id: "points",
          type: "symbol",
          source: "points",
          layout: {
            "icon-image": "custom-marker",
          },
        });
      });
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div
        ref={this.mapContainer}
        className={" map-container " + classes.mapContainer}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(MapBoxHelper);
