import * as React from "react";
import { IconButton } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: "flex",
//         float: "left!important",
//         margin: "10px 0px!important",
//     },
//     paper: {
//         marginRight: theme.spacing(2),
//     },
// }));

export default function ConfirmationPopup(props) {
  const OrderAction = () => {
    confirmAlert({
      overlayClassName: "order-confirm-popup",
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <>
            <IconButton
              className="close-icon"
              onClick={() => {
                props.onClose();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <div className="popup">
              {props.icon}
              <h1>{props.heading}</h1>
              <p>{props.message}</p>
              <p className="order-no">
                {"'" + props.OrderOrProduct.join("', '") + "'"}
              </p>
              <p className="are-you-sure">Are you sure you want to do this?</p>
              <DialogActions>
                <Button
                  variant="contained"
                  className="button-cancel"
                  onClick={() => {
                    props.onClose();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    props.onSubmit();
                    onClose();
                  }}
                  className="button-yes"
                >
                  Yes
                </Button>
              </DialogActions>
            </div>
          </>
        );
      },
    });
  };

  return <div>{OrderAction()}</div>;
}
