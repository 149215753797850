export default function OrderStatus(props) {
  let statusName = parseInt(props.code);

  switch (statusName) {
    case 1: {
      statusName = "New";
      break;
    }
    case 2: {
      statusName = "Deleted";
      break;
    }
    case 3: {
      statusName = "Dispatched";
      break;
    }
    case 4: {
      statusName = "Pending Release";

      break;
    }
    case 5: {
      statusName = "Released";
      break;
    }
    case 6: {
      statusName = "Un-Released";
      break;
    }
    case 7: {
      statusName = "Product Received into Warehouse";
      break;
    }
    case 8: {
      statusName = "All Delivery Products Received At Depot";
      break;
    }
    case 9: {
      ("All Products Not Received At Depot");
      break;
    }
    case 10: {
      statusName = "Scheduled date not confirmed";
      break;
    }
    case 11: {
      statusName = "Scheduled Date Declined";
      break;
    }
    case 12: {
      statusName = "Scheduled Date Confirmed";
      break;
    }
    case 13: {
      statusName = "Completed";
      break;
    }
    case 14: {
      statusName = "Cancelled";
      break;
    }

    case 15: {
      statusName = "Uncancelled";
      break;
    }
    case 16:
    case 22: {
      statusName = "Pending Cancelled";
      break;
    }
    case 17: {
      statusName = "Scheduled Date Slot Confirmed";
      break;
    }
    case 18: {
      statusName = "On Hold";
      break;
    }
    case 19: {
      statusName = "Pending Dispatch";
      break;
    }
    case 21:
    case 23: {
      statusName = "Pending Deleted";
      break;
    }
    case 20: {
      statusName = "Exception";
      break;
    }
    case 24: {
      statusName = "Pending Product Delete";
      break;
    }
    case 25: {
      statusName = "Pending Fulfill";
      break;
    }
    case 26: {
      statusName = "Pending Order Update";
      break;
    }
    default:
      statusName = "Pending";
  }
  return statusName;
}
