import React from "react";

export default function OrderEventLoggedBy(props) {
  switch (props.code) {
    case 1: {
      return <strong className={"order-event-logged-by "}>System</strong>; //order-event-logged-by-system
    }
    case 2: {
      return <strong className={"order-event-logged-by "}>BJS</strong>; //order-event-logged-by-bjs
    }
    case 3: {
      return <strong className={"order-event-logged-by "}>User</strong>; //order-event-logged-by-user
    }
    case 4: {
      return <strong className={"order-event-logged-by "}>Shopify</strong>; //order-event-logged-by-shopify
    }
  }
}
