import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import AppSettings from "app-settings";
import React, { Component } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ReactComponent as CheckIcon } from "../../assets/img/generalIcons_Check.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as SortByIcon } from "../../assets/img/icons_SortBy.svg";
import APIService from "../../services/APIService.js";
// import ListItemText from '@mui/material/ListItemText';
import Help from "../Help/Help.jsx";
import ApiResponseError from "../helper/ApiResponseError.jsx";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
import CheckBoxHelper from "../helper/CheckBoxHelper.jsx";
import NoResultHelper from "../helper/NoResultHelper.jsx";
import Pagination from "../helper/PaginationHelper.jsx";
import SearchBarHelper from "../helper/SearchBarHelper.jsx";
import "./DeliverySchedulePage.scss";

// const { innerHeight: window_height } = window;
// const dataGridHeight = window_height - 398;
const styles = () => ({
  customTableContainer: {
    overflowX: "initial",
    // overflow: "auto",
  },
});

class DeliverySchedulePage extends Component {
  constructor(props) {
    super(props);
    props.getHelp(Help.DeliverySchedulePage);
    this.state = {
      errorAlert: null,
      total_record: 0,
      mainRows: [],
      rows: [],
      filtration: {
        start: 0,
        limit: 30,
        isOrderProduct: "false",
        orderByKey: "",
        order: "",
        searchValueTypedOnly: "",
        searchKey: "",
        searchValue: "",
        areaSearch: [],
      },
    };
    this.ApiRequest = this.ApiRequest.bind(this);
    this.inputSearch = this.inputSearch.bind(this);
    this.areaSearch = this.areaSearch.bind(this);
    this.StartLoader = this.StartLoader.bind(this);
    this.getFiltratedRecords = this.getFiltratedRecords.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.sorting = this.sorting.bind(this);
    this.SearchBox = this.SearchBox.bind(this);
  }

  SearchBox = () => {
    return (
      <SearchBarHelper
        value={this.state.filtration.searchValueTypedOnly}
        placeholder={
          "Search for an area, area code, zone, depot, or post code..."
        }
        onChange={(event) => {
          const { value } = event.target;
          let filtration = this.state.filtration;
          filtration.searchValueTypedOnly = value;
          this.setState({ filtration: filtration });
          if (value == "" || value == null) {
            this.getFiltratedRecords();
          }
        }}
        onSubmit={() => {
          let filtration = this.state.filtration;
          filtration.searchValue = this.state.filtration.searchValueTypedOnly;
          this.setState({ filtration: filtration });
          this.getFiltratedRecords();
        }}
      />
    );
  };

  sorting = (a, b) => {
    // converting to uppercase to have case-insensitive comparison
    const name1 = a[this.state.filtration.orderByKey].toUpperCase();
    const name2 = b[this.state.filtration.orderByKey].toUpperCase();

    let comparison = 0;

    if (this.state.filtration.order == "asc") {
      if (name1 > name2) {
        comparison = 1;
      } else if (name1 < name2) {
        comparison = -1;
      }
    } else {
      if (name1 < name2) {
        comparison = 1;
      } else if (name1 > name2) {
        comparison = -1;
      }
    }
    return comparison;
  };

  // Call ApiRequest Datatable Load
  ApiRequest = () => {
    (async () => {
      APIService.GET(AppSettings.DeliveryScheduleAPI)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              total_record: response.result.length,
              mainRows: response.result,
            });
            this.getFiltratedRecords();
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };
  // Start Loader And Empty Table
  getFiltratedRecords = () => {
    let rows = this.state.mainRows;

    if (this.state.filtration.searchValue) {
      rows = this.inputSearch(rows);
    }
    if (this.state.filtration.areaSearch.length > 0) {
      rows = this.areaSearch(rows);
    }

    if (this.state.filtration.order || this.state.filtration.orderByKey) {
      rows = rows.sort(this.sorting);
    }

    let rowsLength = rows.length;
    rows = rows.slice(
      this.state.filtration.start,
      this.state.filtration.start + this.state.filtration.limit
    );

    this.setState({ rows: rows, total_record: rowsLength });
    this.setState({ loading: false });
  };

  // Start Loader And Empty Table
  StartLoader = (is_empty_datatable = true) => {
    if (is_empty_datatable) this.setState({ rows: [], loading: true });
    else this.setState({ loading: true });
  };
  // Call when refresh the page
  componentDidMount() {
    this.StartLoader();
    this.ApiRequest();
  }

  // On Page change
  handlePageChange = (start) => {
    let filtration = this.state.filtration;
    filtration.start = start;
    this.setState({ filtration: filtration, errorAlert: null });
    this.getFiltratedRecords();
  };

  areaSearch = (rows) => {
    // (async () => {
    let filteredRows = [];
    // let is_result = false;
    for (var i = 0; i < rows.length; i++) {
      let description = rows[i].description;
      if (this.state.filtration.areaSearch.includes(description)) {
        filteredRows.push(rows[i]);
      }
    }
    return filteredRows;
    // })();
  };

  inputSearch = (rows) => {
    // (async () => {
    let filteredRows = [];
    // let is_result = false;
    for (var i = 0; i < rows.length; i++) {
      let postcode_area = rows[i].postcode_area.toLowerCase();
      let description = rows[i].description.toLowerCase();
      let zone = rows[i].zone.toLowerCase();
      let depot_name = rows[i].depot_name.toLowerCase();
      let depot_postcode = rows[i].depot_postcode.toLowerCase();
      if (
        zone.search(this.state.filtration.searchValue.toLowerCase()) != -1 ||
        description.search(this.state.filtration.searchValue.toLowerCase()) !=
          -1 ||
        depot_postcode.search(
          this.state.filtration.searchValue.toLowerCase()
        ) != -1 ||
        depot_name.search(this.state.filtration.searchValue.toLowerCase()) !=
          -1 ||
        postcode_area.search(this.state.filtration.searchValue.toLowerCase()) !=
          -1
      ) {
        filteredRows.push(rows[i]);
      }
    }
    return filteredRows;
    // })();
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.errorAlert}
        <Grid container spacing={3} id="delivery-schedule-page">
          <Grid item xs={12} sm={12} md={3} className="page-title-grid">
            <h1 className="pageTitle">delivery schedule</h1>
            <div className="search-box-mobile">{this.SearchBox()}</div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={5} className="search-box-grid">
            {this.state.rows.length > 0 && (
              <AreaFiltration
                rows={this.state.mainRows}
                value={this.state.filtration.areaSearch}
                onChange={(areaSearch) => {
                  let filtration = this.state.filtration;
                  filtration.searchValue = "";
                  filtration.areaSearch = areaSearch;
                  this.setState({ filtration: filtration });
                  this.getFiltratedRecords();
                }}
              />
            )}
            <div className="search-box-grid-div">{this.SearchBox()}</div>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className="">
              <Typography className="record-count">
                {this.state.total_record}{" "}
                {this.state.total_record < 1 ? "Result" : "Results"}
              </Typography>

              {(this.state.filtration.searchValue != "" ||
                this.state.filtration.areaSearch.length != 0) && (
                <>
                  <a
                    color="primary"
                    className={"btn-clear"}
                    onClick={() => {
                      let filtration = this.state.filtration;

                      filtration.searchValue = "";
                      filtration.areaSearch = [];
                      filtration.searchValueTypedOnly = "";
                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.getFiltratedRecords();
                    }}
                  >
                    Clear Search
                  </a>
                  <span className={"btn-search-value"}>
                    Searched Key - {this.state.filtration.searchValue}{" "}
                    {this.state.filtration.areaSearch.join(", ")}
                  </span>
                </>
              )}
              {(this.state.filtration.orderByKey ||
                this.state.filtration.orderValue) && (
                <a
                  color="primary"
                  className={"btn-clear"}
                  onClick={() => {
                    let filtration = this.state.filtration;

                    filtration.searchValue = "";
                    filtration.searchValueTypedOnly = "";
                    filtration.order = "";
                    filtration.orderByKey = "";
                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.getFiltratedRecords();
                  }}
                >
                  Clear Filters
                </a>
              )}
            </Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>

          <Grid item md={12}>
            <TableContainer
              classes={{ root: classes.customTableContainer }}
              className="delivery-schedule-table"
            >
              <Table stickyHeader>
                <TableHead className="">
                  <TableRow>
                    <TableCell
                      onClick={() => {
                        let filtration = this.state.filtration;
                        filtration.order =
                          filtration.order == "desc" ? "asc" : "desc";
                        filtration.orderByKey = "description";
                        this.setState({ filtration: filtration });
                        this.getFiltratedRecords();
                      }}
                      className={
                        "sorting-row " +
                        (this.state.filtration.orderByKey == "description"
                          ? "active-sorting"
                          : "")
                      }
                    >
                      AREA
                      <SortByIcon />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        let filtration = this.state.filtration;
                        filtration.order =
                          filtration.order == "desc" ? "asc" : "desc";
                        filtration.orderByKey = "zone";
                        this.setState({ filtration: filtration });
                        this.getFiltratedRecords();
                      }}
                      className={
                        "sorting-row " +
                        (this.state.filtration.orderByKey == "zone"
                          ? "active-sorting"
                          : "")
                      }
                    >
                      ZONE
                      <SortByIcon />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        let filtration = this.state.filtration;
                        filtration.order =
                          filtration.order == "desc" ? "asc" : "desc";
                        filtration.orderByKey = "depot_name";
                        this.setState({ filtration: filtration });
                        this.getFiltratedRecords();
                      }}
                      className={
                        "sorting-row " +
                        (this.state.filtration.orderByKey == "depot_name"
                          ? "active-sorting"
                          : "")
                      }
                    >
                      DEPOT NAME
                      <SortByIcon />
                    </TableCell>

                    <TableCell>POST CODE</TableCell>
                    <TableCell>MON</TableCell>
                    <TableCell>TUE</TableCell>
                    <TableCell>WED</TableCell>
                    <TableCell>THU</TableCell>
                    <TableCell>FRI</TableCell>
                    <TableCell>SAT</TableCell>
                    <TableCell>SUN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.loading &&
                    this.state.rows.length > 0 &&
                    this.state.rows.map((row, index) => (
                      <TableRow key={index} className="records">
                        <TableCell>
                          {row.description}
                          <span>{row.postcode_area}</span>
                        </TableCell>
                        <TableCell>{row.zone}</TableCell>
                        <TableCell>
                          {row.depot_name}
                          <span>{row.depot_prefix}</span>
                        </TableCell>
                        <TableCell>{row.depot_postcode}</TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.monday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.tuesday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.wednesday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.thursday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.friday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.saturday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                        <TableCell className="delivery-schedule">
                          {row.delivery_schedule.sunday_availability ? (
                            <CheckIcon className="check-icon" />
                          ) : (
                            <CloseIcon className="close-icon" />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!this.state.loading && this.state.rows.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <NoResultHelper />
                      </TableCell>
                    </TableRow>
                  )}
                  {this.state.loading && (
                    <>
                      {Array.from(new Array(10)).map((val, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={40}
                              width={"100%"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className=""></Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withStyles(styles, { withTheme: true })(DeliverySchedulePage);

class AreaFiltration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.rows,
      placeholder: "All Areas [..]",
      AllOptions: [],
      // selected: []
    };
  }

  // On Page changeDownloadCSV
  componentDidMount = () => {
    let AllOptions = this.state.AllOptions;
    for (var i = 0; i < this.state.rows.length; i++) {
      let description = this.state.rows[i].description;
      if (AllOptions.indexOf(description) == -1) {
        AllOptions.push(description);
      }
    }
    this.setState({ AllOptions: AllOptions });
  };

  render() {
    return (
      <div className="area-search">
        <Typography variant="h6" component="h6">
          Area Select:
        </Typography>

        <Autocomplete
          sx={{ width: 300 }}
          options={this.state.AllOptions}
          multiple
          clearOnBlur={true}
          autoHighlight
          disableCloseOnSelect="true"
          disableClearable="true"
          // value={this.props.value}
          value={this.props.value}
          fullWidth
          onChange={(event, value) => {
            this.props.onChange(value);
          }}
          popupIcon={
            <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
          }
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <CheckBoxHelper
                onChange={() => {}}
                className="area-search-check-box"
                checked={selected}
              />

              <span style={{ marginLeft: "10px" }}>{option}</span>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              onFocus={() => {
                this.setState({ placeholder: "Search ..." });
              }}
              onBlur={() => {
                this.setState({ placeholder: "All Areas [..]" });
              }}
              placeholder={this.state.placeholder}
              IconComponent={() => (
                <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
              )}
              inputProps={{
                ...params.inputProps,
                // autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </div>
    );
  }
}
