import React, { Component } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as SearchIcon } from "../../assets/img/icons_Search.svg";
// import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as BackIcon } from "../../assets/img/navigationIcons_LeftArrow.svg";
// import { ReactComponent as NextIcon } from "../../assets/img/navigationIcons_RightArrow.svg";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { ReactComponent as LinkIcon } from "../../assets/img/settingsIcon_Connected.svg";
import EmailIcon from "@material-ui/icons/Email";
import Grid from "@material-ui/core/Grid";
import HelpFooter from "./HelpFooter.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper.jsx";

import "./HelpCategoryPage.scss";
export default class HelpCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: null,
      nextPrevious: null,
      categories: [],
      title: null,
      activeCategory: null,
      activeQuestion: null,
      textToCopy: null,
      copiedText: null,
    };
    this.getActiveData = this.getActiveData.bind(this);
  }
  getActiveData = (category = null, question = null) => {
    let title = null;
    let activeCategory = null;
    let activeQuestion = null;
    let activeCategoryIndex = null;
    let activeQuestionIndex = null;
    let nextPrevious = null;
    let textToCopy = null;
    this.state.categories.forEach((row, index) => {
      if (row.slug.toLowerCase() == category.toLowerCase()) {
        activeCategoryIndex = index;
        title = row.heading;
        activeCategory = row;
        if (question != "") {
          row.questions.forEach((row2, index2) => {
            if (row2.slug.toLowerCase() == question) {
              activeQuestion = row2;
              title = row2.question;

              activeQuestionIndex = index2;
            }
          });
        }
      }
    });
    let element = null;
    if (activeQuestionIndex != null) {
      if (
        this.state.categories[activeCategoryIndex].questions.length >
        activeQuestionIndex + 1
      )
        element =
          this.state.categories[activeCategoryIndex].questions[
            activeQuestionIndex + 1
          ];
      else element = this.state.categories[activeCategoryIndex].questions[0];

      nextPrevious = (
        <>
          <h1>Next Article</h1>
          <hr />
          <Link
            onClick={() => {
              this.getActiveData(this.state.activeCategory.slug, element.slug);
            }}
            to={"/help/" + activeCategory.slug + "/" + element.slug}
          >
            {element.question}
          </Link>
        </>
      );

      //
      textToCopy = (
        <>
          <CopyToClipboard
            onCopy={() => {
              this.setState({ copiedText: true });
            }}
            text={
              window.location.origin +
              "/help/" +
              activeCategory.slug +
              "/" +
              element.slug
            }
          >
            <span className="copy-text">Copy link to this article</span>
          </CopyToClipboard>
        </>
      );
    } else {
      if (this.state.categories.length > activeCategoryIndex + 1)
        element = this.state.categories[activeCategoryIndex + 1];
      else element = this.state.categories[0];

      nextPrevious = (
        <>
          <h1>Next Category</h1>
          <hr />
          <Link
            onClick={() => {
              this.getActiveData(element.slug);
            }}
            to={"/help/" + element.slug}
          >
            {element.heading}
          </Link>
        </>
      );

      textToCopy = (
        <>
          <CopyToClipboard
            onCopy={() => {
              this.setState({ copiedText: true });
            }}
            text={window.location.origin + "/help/" + activeCategory.slug}
          >
            <span className="copy-text">Copy link to this category</span>
          </CopyToClipboard>
        </>
      );
    }

    this.setState({
      title: title,
      activeQuestion: activeQuestion,
      activeCategory: activeCategory,
      isLoaded: 1,
      nextPrevious: nextPrevious,
      textToCopy: textToCopy,
      copiedText: false,
    });
    this.props.executeScroll();
  };
  componentDidMount() {
    this.props.executeScroll();

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_CMS_ENDPOINT_URL + "/categories",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({ categories: result });
        this.getActiveData(this.props.category, this.props.question);
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    return (
      <div id="help-category" className="help">
        {this.state.isLoaded && (
          <>
            <div className="help-center">
              <Link to="/help" className="back-button">
                <IconButton>
                  <BackIcon />
                </IconButton>
              </Link>
              <h1 className="pageTitle">{this.state.title}</h1>
              <Link to="/help">
                <SearchIcon />
              </Link>
              <a href="javascript:void(0);" className="mail-icon">
                <EmailIcon />
              </a>
            </div>
            <div className="breadcrumb">
              <Link to="/help">Help Home</Link> /{" "}
              {this.state.activeQuestion === null && (
                <span>{this.state.activeCategory.heading}</span>
              )}
              {this.state.activeQuestion != null && (
                <>
                  <Link
                    onClick={() => {
                      this.getActiveData(this.state.activeCategory.slug);
                    }}
                    to={"/help/" + this.state.activeCategory.slug}
                  >
                    {this.state.activeCategory.heading}
                  </Link>{" "}
                  / <span>{this.state.activeQuestion.question}</span>
                </>
              )}
            </div>
            <div className="question-list">
              <Grid container spacing={3}>
                <Grid item md={8} className="main-div">
                  <div>
                    {!this.state.activeQuestion && (
                      <h6>please select an article...</h6>
                    )}
                    {this.state.activeQuestion && (
                      <p>{this.state.activeQuestion.answer}</p>
                    )}
                    {!this.state.activeQuestion && (
                      <ul>
                        {this.state.activeCategory.questions.map(
                          (row2, index) => (
                            <li key={index}>
                              <Link
                                to={
                                  "/help/" +
                                  this.state.activeCategory.slug +
                                  "/" +
                                  row2.slug
                                }
                                onClick={() => {
                                  this.getActiveData(
                                    this.state.activeCategory.slug,
                                    row2.slug
                                  );
                                }}
                              >
                                {row2.question}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                  <div className="copy-link">
                    <span>
                      <LinkIcon />
                    </span>
                    {this.state.textToCopy}
                    {this.state.copiedText && (
                      <small>
                        <span className="copied-text">Copied</span>
                      </small>
                    )}
                  </div>
                </Grid>
                <Grid item md={4} className="category-list">
                  <div className="tree">
                    <ul>
                      {this.state.categories.map((row, index) => (
                        <li key={index}>
                          <span className="list">
                            <Link
                              className={
                                this.state.activeCategory &&
                                row.id == this.state.activeCategory.id
                                  ? "active"
                                  : ""
                              }
                              onClick={() => {
                                this.getActiveData(row.slug);
                              }}
                              to={"/help/" + row.slug}
                            >
                              {row.heading.toLowerCase()}
                            </Link>
                            <AddIcon
                              style={{
                                display:
                                  row.id == this.state.activeCategory.id
                                    ? "none"
                                    : "block",
                              }}
                              className="hide-show-icon"
                              id={"show_button_" + index}
                              onClick={() => {
                                document.getElementById(
                                  "ul_" + index
                                ).style.display = "block";
                                document.getElementById(
                                  "hide_button_" + index
                                ).style.display = "block";
                                document.getElementById(
                                  "show_button_" + index
                                ).style.display = "none";
                              }}
                            />
                            <RemoveIcon
                              style={{
                                display:
                                  row.id == this.state.activeCategory.id
                                    ? "block"
                                    : "none",
                              }}
                              className="hide-show-icon"
                              id={"hide_button_" + index}
                              onClick={() => {
                                document.getElementById(
                                  "ul_" + index
                                ).style.display = "none";
                                document.getElementById(
                                  "hide_button_" + index
                                ).style.display = "none";
                                document.getElementById(
                                  "show_button_" + index
                                ).style.display = "block";
                              }}
                            />
                          </span>
                          <ul
                            style={{
                              display:
                                row.id == this.state.activeCategory.id
                                  ? "block"
                                  : "none",
                            }}
                            id={"ul_" + index}
                          >
                            {row.questions.map((row2, index) => (
                              <li
                                key={index}
                                className={
                                  this.state.activeQuestion &&
                                  row2.id == this.state.activeQuestion.id
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  to={"/help/" + row.slug + "/" + row2.slug}
                                  onClick={() => {
                                    this.getActiveData(row.slug, row2.slug);
                                  }}
                                >
                                  {row2.question}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="next-category">{this.state.nextPrevious}</div>
            <HelpFooter />
          </>
        )}
        {!this.state.isLoaded && (
          <div
            style={{ height: window.innerHeight - 175 + "px", display: "flex" }}
          >
            <LoadingOnPopupHelper />
          </div>
        )}
      </div>
    );
  }
}
