import { IconButton } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import MenuList from "@material-ui/core/MenuList";
// import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AppSettings from "app-settings";
import React from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ReactComponent as CancelOrdersIcon } from "../../assets/img/ctaIcons_Cancel.svg";
import { ReactComponent as DeleteOrdersIcon } from "../../assets/img/ctaIcons_Delete.svg";
import { ReactComponent as DispatchOrdersToBjsIcon } from "../../assets/img/ctaIcons_DispatchOrdersToBJS.svg";
import { ReactComponent as DispatchStockToBjsIcon } from "../../assets/img/ctaIcons_DispatchStockToBJS.svg";
import { ReactComponent as GenerateLabelsIcon } from "../../assets/img/ctaIcons_GenerateLabels.svg";
import { ReactComponent as RetryOrdersIcon } from "../../assets/img/ctaIcons_Retry.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as SubMenuDots } from "../../assets/img/subMenu_Dots.svg";
import { ReactComponent as NoteAddIcon } from "../../assets/img/ctaIcons_AddNotes.svg";

import APIService from "../../services/APIService.js";
import OrderActionResponsePopupHelper from "../helper/OrderActionResponsePopup.jsx";
import ApiResponseError from "./ApiResponseError.jsx";
import OrderPopupTabIndexEnum from "./OrderPopupTabIndexEnum.jsx";
import OrderStatusEnum from "./OrderStatusEnum.jsx";
// import OrderTabEnum from "./OrderTabEnum.jsx";

// subMenu_Dots

export default function OrderActionHelper(props) {
  const Orders = props.Orders;
  const action = props.action ?? null;
  const actionTitle = props.actionTitle ?? null;
  const actionIcon = props.actionIcon ?? null;

  const ProductInfo = props.ProductInfo ? props.ProductInfo : null;

  const [loaderMessage, setLoaderMessage] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(null);
  // const [setBatchOrderIds] = React.useState(null);
  const [OrderActionResponsePopup, setOrderActionResponsePopup] =
    React.useState(null);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const BatchSetter = (batches, orderIds) => {
    let Obj = [];
    let batchIds = [];
    batches.forEach((batch) => {
      let batchOrderNumber = [];
      batch.orders.forEach((order) => {
        if (!orderIds.includes(order.id)) {
          batchIds.push(order.id);
          batchOrderNumber.push(order.order_number);
        }
      });
      if (batchOrderNumber.length > 0) {
        Obj = (
          <>
            {Obj}
            <p>
              Selected Orders are linked to batch: {batch.batch_ref}
              {batchOrderNumber.length > 2 && (
                <ol className="orders-list">
                  {batchOrderNumber.map((order_number, index) => (
                    <li key={index}>&apos;{order_number}&apos;</li>
                  ))}
                </ol>
              )}
              {batchOrderNumber.length <= 2 && (
                <>
                  <br />
                  {"'" + batchOrderNumber.join("', '") + "'"}
                </>
              )}
              {/* {batchOrderNumber.join("&apos;, &apos;")}&apos; */}
            </p>
          </>
        );
      }
    });

    // setBatchOrderIds(batchIds);
    return {
      data: <div className="related-order">{Obj}</div>,
      batchIds: batchIds,
    };
  };

  // On Page changeDownloadCSV
  const OrderAction = (
    orders,
    action,
    title = actionTitle,
    icon = actionIcon
  ) => {
    let batchIds = [];
    if (action == "Release+Create" || action == "Release") {
      orders.forEach((order) => {
        if (order.batch_id) {
          batchIds.push(order.batch_id);
        }
      });

      if (batchIds.length > 0) {
        setOrderActionResponsePopup(true);

        APIService.POST(AppSettings.GetBatchOrders, {
          batch_ids: batchIds,
        })
          .then((response) => response.json())
          .then((response) => {
            setTimeout(() => {
              // }

              if (response && response.success && (response.success = true)) {
                OrderActionStep2(orders, action, title, icon, response.result);
              } else {
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              }
            }, 1000);
          })
          .catch(() => {
            setErrorAlert(
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            );
          });
      } else {
        OrderActionStep2(orders, action, title, icon);
      }
    } else {
      OrderActionStep2(orders, action, title, icon);
    }
    // GetBatchOrders
  };
  const OrderActionStep2 = (
    orders,
    action,
    title = actionTitle,
    icon = actionIcon,
    batchData = []
  ) => {
    setOrderActionResponsePopup(false);
    let relatedOrders = BatchSetter(
      batchData,
      orders.map((order) => order.id)
    );

    confirmAlert({
      overlayClassName: "order-confirm-popup",
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <>
            <IconButton
              className="close-icon"
              onClick={() => {
                onClose();
                props.onCancelClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            <div className="popup">
              {icon}
              <h1>{title}</h1>
              {action == "Cancel" && (
                <p>
                  You have requested to cancel order
                  {orders.length == 1 ? "" : "s"}:
                </p>
              )}

              {action != "Cancel" && (
                <p>
                  You are about to {title} for the following order
                  {orders.length == 1 ? "" : "s"}:
                </p>
              )}

              <div className="order-related-orders">
                <p className="order-no ">
                  {orders.length <= 2 && (
                    <>
                      {"'" +
                        orders.map((order) => order.order_number).join("', '") +
                        "'"}
                    </>
                  )}
                  {orders.length > 2 && (
                    <ol className="orders-list">
                      {orders.map((order, index) => (
                        <li key={index}>
                          {"'"}
                          {order.order_number}
                          {"'"}
                        </li>
                      ))}
                    </ol>
                  )}
                </p>
                {relatedOrders.data}
              </div>
              {action == "Cancel" && (
                <p className="are-you-sure">
                  Are you sure you want to cancel the following order
                  {orders.length == 1 ? "" : "s"}?
                </p>
              )}

              {action != "Cancel" && (
                <p className="are-you-sure">
                  Are you sure you want to do this?
                </p>
              )}
              <DialogActions>
                <Button
                  variant="contained"
                  className="button-cancel"
                  onClick={() => {
                    onClose();
                    props.onCancelClose();
                  }}
                >
                  Back
                </Button>
                {action == "Cancel" && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        onClose();
                        OrderActionPerform(
                          orders.map((order) => order.id),
                          action
                        );
                      }}
                      className="button-yes"
                    >
                      On BJS
                    </Button>
                    {APIService.GetStoreConfig("update_order_status_shopify") ==
                      1 && (
                      <Button
                        variant="contained"
                        onClick={() => {
                          onClose();
                          OrderActionPerform(
                            orders.map((order) => order.id),
                            action,
                            true
                          );
                        }}
                        className="button-yes"
                      >
                        On BJS & Shopify
                      </Button>
                    )}
                  </>
                )}
                {action != "Cancel" && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      onClose();
                      OrderActionPerform(
                        orders.map((order) => order.id),
                        action
                      );
                    }}
                    className="button-yes"
                  >
                    Yes
                  </Button>
                )}
                {relatedOrders.batchIds && relatedOrders.batchIds.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      onClose();
                      OrderActionPerform(
                        relatedOrders.batchIds.concat(
                          orders.map((order) => order.id)
                        ),
                        action
                      );
                    }}
                    className="button-yes"
                  >
                    Complete Batch
                  </Button>
                )}
              </DialogActions>
            </div>
          </>
        );
      },
    });
  };
  const closeAlertDialog = () => {
    setOrderActionResponsePopup(true);
  };
  const OrderActionPerform = (id, action, is_shopify_deleted = false) => {
    setOrderActionResponsePopup(true);
    setLoaderMessage("");
    (async () => {
      let ids = typeof id == "object" ? id : [id];
      // var Result = null;
      switch (action) {
        case "Release":
          {
            APIService.POST(AppSettings.ReleaseOrdersAPI, {
              order_ids: ids,
              release: true,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Create":
          {
            APIService.POST(AppSettings.ReleaseOrdersAPI, {
              order_ids: ids,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Retry":
          {
            APIService.POST(AppSettings.RetryOrderAPI, {
              order_ids: ids,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Release+Create":
          {
            APIService.POST(AppSettings.ReleaseOrdersAPI, {
              order_ids: ids,
              release: true,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Cancel":
          {
            APIService.POST(AppSettings.CancelOrdersAPI, {
              order_ids: ids,
              is_shopify_deleted: is_shopify_deleted,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Label":
          {
            setLoaderMessage("Label generation in progress");
            APIService.POST(AppSettings.GetLabelAPI, {
              order_ids: ids,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;
        case "Delete":
          {
            APIService.POST(AppSettings.DeleteOrdersAPI, {
              order_ids: ids,
              is_shopify_deleted: is_shopify_deleted,
            })
              .then((response) => {
                ApiResponseHandler(response);
              })
              .catch(() => {
                setOrderActionResponsePopup(false);
                setErrorAlert(
                  <ApiResponseError
                    response={{
                      message:
                        process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                    }}
                  />
                );
              });
          }
          break;

        default:
          break;
      }
    })();
  };
  const ApiResponseHandler = (response) => {
    const contentType = response.headers.get("content-type");
    if (contentType.indexOf("application/json") !== -1) {
      return response.json().then((data) => {
        if (data.success == true) {
          setOrderActionResponsePopup(data.result);
        } else {
          // console.log(data);
          setOrderActionResponsePopup(false);
          setErrorAlert(
            <ApiResponseError
              onClick={() => {
                props.onCancelClose();
              }}
              response={data}
            />
          );
        }
      });
    } else {
      return response.blob().then((blob) => {
        let OrdersNotGeneratedLabels = [];
        let orderForDownload = [];
        Orders.map((order) => {
          let response =
            OrderStatusEnum.CheckOrderStatusIsValidForGenerateLabels([order]);
          if (!response.success) {
            OrdersNotGeneratedLabels.push({
              id: order.id,
              status: false,
              order_status: order.status_id,
              order_number: order.order_number,
              message:
                "Label cannot be generated for order without consignment number.",
            });
          } else {
            OrdersNotGeneratedLabels.push({
              id: order.id,
              status: true,
              order_status: order.status_id,
              order_number: order.order_number,
              message: "Label has been generated for Order.",
            });
            orderForDownload.push(order.order_number);
          }
        });

        setOrderActionResponsePopup(OrdersNotGeneratedLabels);

        if (orderForDownload.length > 0) {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            orderForDownload[0] + " Orders_" + orderForDownload.length + `.pdf`
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      });
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  if (action !== null) {
    return (
      <>
        {OrderActionResponsePopup && (
          <OrderActionResponsePopupHelper
            loaderMessage={loaderMessage}
            handleCloseAlertDialog={() => {}}
            result={OrderActionResponsePopup}
            refreshData={props.onSuccessClose}
          />
        )}
        {OrderActionResponsePopup === null &&
          Orders.length != 0 &&
          OrderAction(Orders, action)}
        {errorAlert}
      </>
    );
  }

  const CheckOrderStatusIsValidForCreate =
    OrderStatusEnum.CheckOrderStatusIsValidForCreate(Orders);
  const CheckOrderStatusIsValidForRelease =
    OrderStatusEnum.CheckOrderStatusIsValidForRelease(Orders);
  const CheckOrderStatusIsValidForGenerateLabels =
    OrderStatusEnum.CheckOrderStatusIsValidForGenerateLabels(Orders);
  const CheckOrderStatusIsValidForAddNotes =
    OrderStatusEnum.CheckOrderStatusIsValidForAddNotes(Orders);
  const CheckOrderStatusIsValidForRetry =
    OrderStatusEnum.CheckOrderStatusIsValidForRetry(Orders);
  const CheckOrderStatusIsValidForCancelOrder =
    OrderStatusEnum.CheckOrderStatusIsValidForCancelOrder(Orders);
  const CheckOrderStatusIsValidForDeleteOrder =
    OrderStatusEnum.CheckOrderStatusIsValidForDeleteOrder(Orders);

  return (
    <>
      {OrderActionResponsePopup && (
        <OrderActionResponsePopupHelper
          handleCloseAlertDialog={closeAlertDialog}
          result={OrderActionResponsePopup}
          refreshData={props.onSuccessClose}
          loaderMessage={loaderMessage}
        />
      )}

      <IconButton
        onClick={handleToggle}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        variant="outlined"
        className={"action-bar"}
        disabled={
          !(
            (CheckOrderStatusIsValidForCreate.success &&
              CheckOrderStatusIsValidForCreate.message == null) ||
            (CheckOrderStatusIsValidForRelease.success &&
              CheckOrderStatusIsValidForRelease.message == null) ||
            (CheckOrderStatusIsValidForGenerateLabels.success &&
              CheckOrderStatusIsValidForGenerateLabels.message == null) ||
            (CheckOrderStatusIsValidForAddNotes.success &&
              CheckOrderStatusIsValidForAddNotes.message == null) ||
            (CheckOrderStatusIsValidForRetry.success &&
              CheckOrderStatusIsValidForRetry.message == null) ||
            (CheckOrderStatusIsValidForCancelOrder.success &&
              CheckOrderStatusIsValidForCancelOrder.message == null) ||
            (CheckOrderStatusIsValidForDeleteOrder.success &&
              CheckOrderStatusIsValidForDeleteOrder.message == null)
          )
        }
      >
        <SubMenuDots />
      </IconButton>
      <Menu
        anchorEl={anchorRef.current}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuList
          autoFocusItem={open}
          className="Order-action-bar"
          onKeyDown={handleListKeyDown}
        >
          {((!APIService.GetStoreConfig("create_orders") &&
            !APIService.GetStoreConfig("batch_orders_auto")) ||
            Orders[0].status_id == OrderStatusEnum.DELETED()) &&
            CheckOrderStatusIsValidForCreate.success == true &&
            CheckOrderStatusIsValidForCreate.message == null && (
              <MenuItem
                onClick={() =>
                  OrderAction(
                    Orders,
                    "Create",
                    "Dispatch Order to BJS",
                    <DispatchOrdersToBjsIcon />
                  )
                }
              >
                <DispatchOrdersToBjsIcon /> Dispatch Order to BJS
              </MenuItem>
            )}
          {(!APIService.GetStoreConfig("released_orders") ||
            Orders[0].status_id == OrderStatusEnum.DELETED()) &&
            CheckOrderStatusIsValidForRelease.success == true &&
            CheckOrderStatusIsValidForRelease.message == null && (
              <MenuItem
                onClick={() =>
                  OrderAction(
                    Orders,
                    "Release+Create",
                    "Release Stock to BJS ",
                    <DispatchStockToBjsIcon />
                  )
                }
              >
                <DispatchStockToBjsIcon /> Release Stock to BJS
              </MenuItem>
            )}

          {CheckOrderStatusIsValidForGenerateLabels.success == true &&
            CheckOrderStatusIsValidForGenerateLabels.message == null && (
              <MenuItem
                onClick={() =>
                  OrderAction(
                    Orders,
                    "Label",
                    "Generate Labels",
                    <GenerateLabelsIcon />
                  )
                }
              >
                <GenerateLabelsIcon /> Generate Labels
              </MenuItem>
            )}

          {ProductInfo &&
            CheckOrderStatusIsValidForAddNotes.success == true &&
            CheckOrderStatusIsValidForAddNotes.message == null && (
              <MenuItem
                onClick={() =>
                  ProductInfo(Orders[0], OrderPopupTabIndexEnum.ORDER_NOTES())
                }
                className={"order-note-icon"}
              >
                <NoteAddIcon /> Order Note
              </MenuItem>
            )}
          {CheckOrderStatusIsValidForRetry.success == true &&
            CheckOrderStatusIsValidForRetry.message == null && (
              <MenuItem
                onClick={() => {
                  OrderAction(
                    Orders,
                    "Retry",
                    "retry again",
                    <RetryOrdersIcon />
                  );
                }}
              >
                <RetryOrdersIcon /> Retry
              </MenuItem>
            )}
          {CheckOrderStatusIsValidForCancelOrder.success == true &&
            CheckOrderStatusIsValidForCancelOrder.message == null && (
              <MenuItem
                className="cancel"
                onClick={() =>
                  OrderAction(
                    Orders,
                    "Cancel",
                    "Cancel Order",
                    <CancelOrdersIcon className="cancel" />
                  )
                }
              >
                <CancelOrdersIcon /> Cancel
              </MenuItem>
            )}
          {CheckOrderStatusIsValidForDeleteOrder.success == true &&
            CheckOrderStatusIsValidForDeleteOrder.message == null && (
              <MenuItem
                className="delete"
                onClick={() => {
                  OrderAction(
                    Orders,
                    "Delete",
                    "Delete Order To BJS",
                    <DeleteOrdersIcon className="delete" />
                  );
                }}
              >
                <DeleteOrdersIcon /> Delete
              </MenuItem>
            )}
        </MenuList>
      </Menu>

      {errorAlert}
    </>
  );
}
