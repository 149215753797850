import { Button, Grid, TableRow, withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Skeleton from "@mui/material/Skeleton";
import AppSettings from "app-settings";
import React, { Component } from "react";
import OrderActionHelper from "views/helper/OrderActionHelper.jsx";
import { ReactComponent as BjsIcon } from "../../assets/img/BJSTypeLogo.svg";
import APIService from "../../services/APIService.js";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import OrderActionResponsePopup from "../helper/OrderActionResponsePopup.jsx";
import OrderPopupTabIndexEnum from "../helper/OrderPopupTabIndexEnum.jsx";
import DateConvertor from "./../helper/core/DateConvertor.jsx";
import OrderStatus from "./../helper/OrderStatus.jsx";
import OrderDialogBox from "./../OrdersPage/OrderDialogBox";
import "./DispatchesPage.scss";

// const { innerHeight: window_height } = window;
// const dataGridHeight = window_height - 300;
const styles = () => ({
  // dataGrid: {
  //   maxHeight: dataGridHeight + "px!important",
  //   overflow: "auto",
  //   display: "table-caption",
  // },
  // tableTbodyTr: {
  //   display: "table",
  //   width: "100%",
  //   tableLayout: "fixed",
  // },
  // tableThead: {
  //   width: "calc( 100% - 1px )",
  // },
  // table: {
  //   display: "grid",
  // },
  // orderNum: {
  //   width: "10%",
  // },
  // ordered: {
  //   width: "13%",
  // },
  // customerName: {
  //   width: "15%",
  // },
  // address: {
  //   width: "30%",
  // },
  // orderStatus: {
  //   width: "12%",
  // },
  // orderAction: {
  //   width: "20%",
  // },
  // orderActionDiv: {
  //   width: '100%',
  //   justifyContent: 'end',
  //   display: 'flex',
  //   float: 'left',
  // },
  customTableContainer: {
    overflowX: "initial",
  },
});

class DispatchOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorAlert: null,
      open: false,
      popUpclickedOrder: null,
      isOpenOrderDetail: false,
      showTotalRecord: 0,
      filtration: {
        start: 0,
        limit: 3,
        isOrderProduct: "false",
        orderByKey: "",
        order: "",
        searchKey: "",
        searchValue: "",
        status: 0,
        batch_id: props.dispatchId,
      },
      batch_id: props.dispatchId,
      isQuickFilters: false,
      total_record: 0,
      rows: [],
    };
    this.ApiRequest = this.ApiRequest.bind(this);
    this.StartLoader = this.StartLoader.bind(this);
    this.productInfo = this.productInfo.bind(this);
    this.RefreshData = this.RefreshData.bind(this);
    this.getAddress = this.getAddress.bind(this);
  }
  // Call ApiRequest Datatable Load
  ApiRequest = (url = "") => {
    url = `?batch_id=${this.state.batch_id}`;
    (async () => {
      APIService.GET(AppSettings.GetOrdersAPI + url)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            this.setState({
              total_record: response.result.total_record,
              rows: response.result.data.orders,
              showTotalRecord: response.result.data.filtered_records,
            });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };

  // productInfo = (param) => {
  //   this.setState({
  //     popUpclickedOrder: param,
  //     isOpenOrderDetail: true,
  //   });
  // };
  productInfo = (
    param,
    index,
    tabIndexing = OrderPopupTabIndexEnum.OVERVIEW()
  ) => {
    let filtration = Object.assign({}, this.state.filtration);
    filtration.start = filtration.start + index;
    this.setState({
      popUpclickedOrder: (
        <OrderDialogBox
          order={param}
          tabIndex={tabIndexing}
          handleCloseOrderDetail={() => {
            this.setState({
              popUpclickedOrder: "",
            });
            this.StartLoader();
            this.ApiRequest();
          }}
          filtration={filtration}
        />
      ),
    });
  };

  getAddress = (row) => {
    let address =
      (row.addressline1 != "" ? row.addressline1 + ", " : "") +
      (row.addressline2 != "" ? row.addressline2 + ", " : "") +
      (row.addressline3 != "" ? row.addressline3 + ", " : "") +
      (row.city != "" ? row.city + ", " : "") +
      (row.state != "" ? row.state + ", " : "") +
      // (row.zipcode != "" ? row.zipcode + ", " : "") +
      (row.country != "" ? row.country + " " : "");
    address = address.replace(/\s+/g, " ").trim();
    if (address.length > 95) {
      return address.substring(0, 95) + "...";
    } else {
      return address;
    }
  };
  // Start Loader And Empty Table
  StartLoader = (is_empty_datatable = true) => {
    if (is_empty_datatable) this.setState({ rows: [], loading: true });
    else this.setState({ loading: true });
  };
  // Call when refresh the page
  componentDidMount() {
    this.StartLoader();
    this.ApiRequest();
  }
  RefreshData = () => {
    this.StartLoader();
    this.ApiRequest();
  };
  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={3}>
        {this.state.errorAlert}
        <Grid
          item
          md={12}
          style={{
            maxHeight: "400px",
            overflow: "auto",
            marginTop: "12px",
            paddingTop: "0px",
          }}
        >
          <TableContainer
            classes={{ root: classes.customTableContainer }}
            className="dispatch-orders"
          >
            <Table stickyHeader>
              <TableHead className={classes.tableThead}>
                <TableRow className={classes.tableTbodyTr}>
                  <TableCell>ORDER NUM</TableCell>
                  <TableCell>
                    CREATED <BjsIcon className="created-icon" />
                  </TableCell>
                  <TableCell>CUSTOMER NAME</TableCell>
                  <TableCell>ADDRESS</TableCell>
                  <TableCell>POST CODE</TableCell>
                  <TableCell>ORDER STATUS</TableCell>
                  <TableCell />
                  {/* <TableCell>
                    <div>
                      TOTAL ORDERS: {this.state.showTotalRecord}
                    </div>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataGrid}>
                {this.state.loading && (
                  <>
                    {Array.from(new Array(10)).map((val, index) => (
                      <TableRow key={index}>
                        <TableCell colSpan={12}>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            height={40}
                            width={"100%"}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
                {this.state.rows.map((row, index) => (
                  <React.Fragment key={row.id}>
                    <TableRow key={row.id}>
                      <TableCell>{row.order_number}</TableCell>
                      <TableCell>
                        {DateConvertor(row.shopify_created_at)}
                      </TableCell>
                      <TableCell>
                        {row.first_name} {row.middle_name} {row.last_name}
                      </TableCell>
                      <TableCell className="address">
                        {this.getAddress(row)}
                      </TableCell>
                      <TableCell>{row.zipcode}</TableCell>
                      <TableCell className="order-status">
                        <OrderStatus code={row.status_id} />
                      </TableCell>
                      {/* <TableCell>
                        <div>
                          <Button
                            className={"more-info "}
                            component="button"
                            size="small"
                            onClick={() => {
                              this.productInfo(row);
                            }}

                          >
                            MORE INFO
                          </Button>
                          <OrderActionHelper
                            RowInfo={row}
                            ProductInfo={this.productInfo}
                            id={row.id}
                            RefreshData={this.RefreshData}
                            status_id={row.status_id}
                            OrderAction={this.OrderAction}
                          />
                        </div>
                      </TableCell> */}
                      <TableCell className="order-action">
                        {
                          <div>
                            <Button
                              className={"more-info "}
                              component="button"
                              size="small"
                              onClick={() => {
                                this.productInfo(row, index);
                              }}
                            >
                              MORE INFO
                            </Button>
                            <OrderActionHelper
                              ProductInfo={() => {
                                this.productInfo(row, index, 2);
                              }}
                              Orders={[row]}
                              onSuccessClose={() => {
                                this.RefreshData();
                              }}
                              onCancelClose={() => {}}
                            />
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {this.state.popUpclickedOrder}

        {this.state.falseActionPopup && (
          <OrderActionResponsePopup
            handleCloseAlertDialog={() => {
              this.setState({
                falseActionPopup: null,
              });
            }}
            result={this.state.falseActionPopup}
          />
        )}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DispatchOrder);
