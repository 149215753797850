class AppSettings {
  static BaseApiUrl = process.env.REACT_APP_BASE_API_URL;
  static GetStoresAPI = this.BaseApiUrl + "/store/get";
  static GetStoresByNameAPI = this.BaseApiUrl + "/store/test";
  static GetSettingsAPI = this.BaseApiUrl + "/storeconfig/get";
  static SetSettingsAPI = this.BaseApiUrl + "/storeconfig/update";
  static AddSettingsAPI = this.BaseApiUrl + "/storeconfig/insert";
  static DeleteOrdersAPI = this.BaseApiUrl + "/orders/delete";
  static GetOrdersAPI = this.BaseApiUrl + "/orders/getall";
  static GetOrdersWithShopifyId = this.BaseApiUrl + "/orders/shopify_id";
  static ReleaseOrdersAPI = this.BaseApiUrl + "/order/release";
  static CancelOrdersAPI = this.BaseApiUrl + "/order/cancel";
  static RetryOrderAPI = this.BaseApiUrl + "/order/retry/";
  static GetSingleOrderAPI = this.BaseApiUrl + "/order/";
  static ValidateStoreAPI = this.BaseApiUrl + "/install_step_2";
  static DeliveryScheduleAPI = this.BaseApiUrl + "/zone-config";
  static GetProductAPI = this.BaseApiUrl + "/Product/Get";
  static DownloadProductCSVAPI = this.BaseApiUrl + "/Product/csv/download";
  static UploardProductCSVAPI = this.BaseApiUrl + "/Product/csv";
  static GetLabelAPI = this.BaseApiUrl + "/order/getlabels";
  static GetProductUpdateAPI = this.BaseApiUrl + "/Product/Update";
  static GetProductAddAPI = this.BaseApiUrl + "/Product/Add";
  static ProductDelete = this.BaseApiUrl + "/Product/Delete";
  static GetDispatchAPI = this.BaseApiUrl + "/batch/get";
  static GetRefreshToken = this.BaseApiUrl + "/cron/ref-token";
  static GetOrderLog = this.BaseApiUrl + "/Order/logs/";
  static AddOrderNotes = this.BaseApiUrl + "/Order/notes/add/";
  static GetOrderNotes = this.BaseApiUrl + "/Order/notes/get/";
  static SetSingleDetailUpdate = this.BaseApiUrl + "/Order/update/";
  static UpdateStoreApiMode = this.BaseApiUrl + "/storeconfig/update/api-mode";
  static GetOrderDispatchProductsCount =
    this.BaseApiUrl + "/get-order-dispatch-products-count";
  static GetBatchOrders = this.BaseApiUrl + "/batch-orders";
}

export default AppSettings;
