import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { ReactComponent as BJSNoDataIcon } from "../../assets/img/BJSNoDataIllustration.svg";

class NoResultHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   ConsignmentNo: props.no,
      //   children: props.children,
    };
  }

  render() {
    return (
      <Grid container spacing={2} className="no-result">
        <Grid item xs={2} />
        <Grid item xs={4} className="icon">
          <BJSNoDataIcon />
        </Grid>
        <Grid item xs={4}>
          <h1>NO results</h1>
          <p>We couldn&quot;t find any data matching your search.</p>
          <hr />
          <h4>Refine your filters or consider using another search term.</h4>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );
  }
}

export default NoResultHelper;
