import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AppSettings from "app-settings";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as AssemblyIcon } from "../../assets/img/productsIcon_Assembly.svg";
import APIService from "../../services/APIService.js";
import { LoadingOnPopupHelper } from "../helper/LoadingHelper.jsx";
import "./AddEditProduct.scss";
import StarIcon from "@mui/icons-material/Star";
// import FileUploadIcon from '@mui/icons-material/FileUpload';

const { innerHeight: window_height } = window;
const dataGridHeight = window_height - 230;
const styles = () => ({
  dataGrid: {
    height: dataGridHeight + "px",
    overflow: "auto",
  },
  customTableContainer: {
    overflowX: "initial",
  },
});

class AddEditProduct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiResponseMessage: null,
      isLoading: false,
      productImage: this.props.editDate
        ? this.props.editDate.img_url == "" ||
          this.props.editDate.img_url == null
          ? "images/productsIcon_Upload.svg"
          : this.props.editDate.img_url
        : "images/productsIcon_Upload.svg",
      editForm: {
        id: this.props.editDate ? this.props.editDate.id : null,
        image: null,
        product_code: this.props.editDate
          ? this.props.editDate.product_code
          : null,
        product_title: this.props.editDate ? this.props.editDate.title : null,
        description: this.props.editDate
          ? this.props.editDate.description
          : null,
        barcode: this.props.editDate ? this.props.editDate.barcode : null,
        weight: this.props.editDate ? this.props.editDate.weight : null,
        height: this.props.editDate ? this.props.editDate.height : null,
        width: this.props.editDate ? this.props.editDate.width : null,
        length: this.props.editDate ? this.props.editDate.length : null,
        price: this.props.editDate ? this.props.editDate.price : null,
        compare_at_price: this.props.editDate
          ? this.props.editDate.compare_at_price
          : 0,
        cbm: this.props.editDate ? this.props.editDate.cbm : null,
        stock: this.props.editDate ? this.props.editDate.stock : null,
        assembly_allowed: this.props.editDate
          ? this.props.editDate.assembly_allowed
          : null,
        assembly_build: this.props.editDate
          ? this.props.editDate.assembly_build
          : null,
        shopify_product_id: this.props.editDate
          ? this.props.editDate.shopify_product_id
          : null,
        shopify_variant_id: this.props.editDate
          ? this.props.editDate.shopify_variant_id
          : null,
        apply_to_all_variants: false,
      },
    };

    this.editFormSubmit = this.editFormSubmit.bind(this);
  }

  editFormSubmit = () => {
    this.setState({ isLoading: true });

    if (
      this.state.editForm.product_code == "" ||
      this.state.editForm.product_code == null ||
      this.state.editForm.product_title == "" ||
      this.state.editForm.product_title == null ||
      // this.state.editForm.description == "" || this.state.editForm.description == null ||
      // this.state.editForm.description == "" || this.state.editForm.description == null ||
      this.state.editForm.cbm == "" ||
      this.state.editForm.cbm == null ||
      this.state.editForm.stock == "" ||
      this.state.editForm.stock == null ||
      this.state.editForm.weight == "" ||
      this.state.editForm.weight == null ||
      this.state.editForm.price == "" ||
      this.state.editForm.price == null
    ) {
      setTimeout(() => {
        this.setState({
          isLoading: false,
          apiResponseMessage: (
            <Alert severity="error">
              All (<StarIcon style={{ height: "10px", width: "10px" }} />)
              marked fields are mandatory.
            </Alert>
          ),
        });
      }, 1);

      return;
    }

    var formData = new FormData();

    for (let prop in this.state.editForm) {
      formData.append(prop, this.state.editForm[prop]);
    }

    APIService.FormPOST(
      this.state.editForm.id
        ? AppSettings.GetProductUpdateAPI
        : AppSettings.GetProductAddAPI,
      formData
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          let editForm;
          let productImage = this.state.productImage;
          if (this.state.editForm.id) editForm = this.state.editForm;
          else {
            editForm = {
              id: null,
              image: null,
              product_code: null,
              product_title: null,
              description: null,
              barcode: null,
              weight: null,
              height: null,
              width: null,
              length: null,
              price: null,
              cbm: null,
              compare_at_price: 0,
              stock: null,
              assembly_allowed: null,
              assembly_build: null,
              shopify_product_id: null,
              shopify_variant_id: null,
              apply_to_all_variants: null,
            };
            productImage = "images/productsIcon_Upload.svg";
          }
          this.setState({
            apiResponseMessage: (
              <Alert severity="success">{response.message}</Alert>
            ),
            editForm: editForm,
            productImage: productImage,
            isLoading: false,
          });
        } else {
          let message = process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE;
          if (response.Message) {
            let responseMessage = response.Message;
            message = responseMessage[Object.keys(responseMessage)[0]][0];
          } else if (response.message) {
            message = response.message;
          }

          this.setState({
            isLoading: false,
            apiResponseMessage: <Alert severity="error">{message}</Alert>,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          apiResponseMessage: (
            <Alert severity="error">
              {process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE}
            </Alert>
          ),
        });
      });
  };
  render() {
    // const { classes } = this.props;

    return (
      <>
        <Dialog
          open={true}
          onClose={() => {
            this.props.onClose();
          }}
          className="add-edit-product-popup"
        >
          {this.state.isLoading && <LoadingOnPopupHelper />}
          {!this.state.isLoading && (
            <>
              <DialogTitle>
                <span>{this.props.editDate ? "Edit" : "Add"} Product</span>
                <IconButton
                  className="close-icon"
                  onClick={() => {
                    this.props.onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <form
                  onFocus={() => {
                    this.setState({ apiResponseMessage: null });
                  }}
                >
                  <Grid container spacing={3}>
                    {/* {this.state.apiResponseMessage} */}
                    {this.state.apiResponseMessage && (
                      <Grid item xs={12} sm={12} md={12}>
                        {this.state.apiResponseMessage}
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ display: "flex" }}
                      className="add-edit-grid"
                    >
                      <div className={"code-title-image mobile"}>
                        <div className={"image-div"}>
                          <img
                            style={{
                              width: "100%",
                            }}
                            src={this.state.productImage}
                          />
                          <div className="drug-drop">
                            {/* <FileUploadIcon /> */}
                            {/* <label htmlFor="file"><strong>{this.state.editForm.image ?"Change":"Choose"} a file</strong><span class="box__dragndrop"> or drag it here</span>.</label> */}
                          </div>
                          <input
                            title=""
                            type="file"
                            accept="image/*"
                            className="image-input"
                            onChange={(event) => {
                              if (event.target.files && event.target.files[0]) {
                                let editForm = this.state.editForm;
                                editForm.image = event.target.files[0];
                                this.setState({ editForm: editForm });

                                let reader = new FileReader();
                                reader.onload = (e) => {
                                  this.setState({
                                    productImage: e.target.result,
                                    editForm: editForm,
                                  });
                                };
                                reader.readAsDataURL(event.target.files[0]);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="code-title-image">
                        <div className="code-title">
                          <Typography variant="p" component="h6" fullWidth>
                            Product Code
                            <StarIcon />
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            value={this.state.editForm.product_code}
                            onClick={() => {
                              this.setState({ uploadProductAlert: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.product_code = value;
                              this.setState({ editForm: editForm });
                            }}
                          />
                        </div>
                        <div className="code-title">
                          <Typography variant="p" component="h6" fullWidth>
                            PRODUCT TITLE
                            <StarIcon />
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            value={this.state.editForm.product_title}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.product_title = value;
                              this.setState({ editForm: editForm });
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="code-title-image desktop"
                        style={{ display: "flex" }}
                      >
                        <div className={"image-div"}>
                          <img
                            style={{
                              width: "100%",
                            }}
                            src={this.state.productImage}
                          />
                          <div className="drug-drop">
                            {/* <FileUploadIcon /> */}
                            {/* <label htmlFor="file"><strong>{this.state.editForm.image ?"Change":"Choose"} a file</strong><span class="box__dragndrop"> or drag it here</span>.</label> */}
                          </div>
                          <input
                            title=""
                            type="file"
                            accept="image/*"
                            className="image-input"
                            onChange={(event) => {
                              if (event.target.files && event.target.files[0]) {
                                let editForm = this.state.editForm;
                                editForm.image = event.target.files[0];
                                this.setState({ editForm: editForm });

                                let reader = new FileReader();
                                reader.onload = (e) => {
                                  this.setState({
                                    productImage: e.target.result,
                                    editForm: editForm,
                                  });
                                };
                                reader.readAsDataURL(event.target.files[0]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="p" component="h6" fullWidth>
                        PRODUCT DESCRIPTION
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        required
                        variant="outlined"
                        size="small"
                        value={this.state.editForm.description}
                        onClick={() => {
                          this.setState({ uploadProductAlert: null });
                        }}
                        onChange={(event) => {
                          let { value } = event.target;
                          let editForm = this.state.editForm;
                          editForm.description = value;
                          this.setState({ editForm: editForm });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="p"
                        component="h6"
                        className="boxed-dimensions-title"
                        fullWidth
                      >
                        BOX DIMENSIONS
                      </Typography>
                      <div className="boxed-dimension">
                        <div className="height-width-depth">
                          <Typography variant="p" component="h6" fullWidth>
                            height
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            type="number"
                            variant="outlined"
                            size="small"
                            value={this.state.editForm.height}
                            onClick={() => {
                              this.setState({ apiResponseMessage: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.height = value;

                              editForm.cbm =
                                (editForm.height ?? 1) *
                                (editForm.width ?? 1) *
                                (editForm.length ?? 1);
                              editForm.cbm = editForm.cbm.toFixed(2);

                              this.setState({ editForm: editForm });
                            }}
                            InputProps={{
                              inputProps: { min: 0, step: 0.01 },
                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="height-width-depth">
                          <Typography variant="p" component="h6" fullWidth>
                            width
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            type="number"
                            variant="outlined"
                            size="small"
                            value={this.state.editForm.width}
                            onClick={() => {
                              this.setState({ apiResponseMessage: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.width = value;
                              editForm.cbm =
                                (editForm.height ?? 1) *
                                (editForm.width ?? 1) *
                                (editForm.length ?? 1);
                              editForm.cbm = editForm.cbm.toFixed(2);

                              this.setState({ editForm: editForm });
                            }}
                            InputProps={{
                              inputProps: { min: 0, step: 0.01 },

                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="height-width-depth">
                          <Typography variant="p" component="h6" fullWidth>
                            DEPTH
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            type="number"
                            value={this.state.editForm.length}
                            onClick={() => {
                              this.setState({ apiResponseMessage: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.length = value;
                              editForm.cbm =
                                (editForm.height ?? 1) *
                                (editForm.width ?? 1) *
                                (editForm.length ?? 1);
                              editForm.cbm = editForm.cbm.toFixed(2);

                              this.setState({ editForm: editForm });
                            }}
                            InputProps={{
                              inputProps: { min: 0, step: 0.01 },

                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="height-width-depth">
                          <Typography variant="p" component="h6" fullWidth>
                            CBM <StarIcon />
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            type="number"
                            value={this.state.editForm.cbm}
                            onClick={() => {
                              this.setState({ apiResponseMessage: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.cbm = value;
                              this.setState({ editForm: editForm });
                            }}
                            InputProps={{
                              inputProps: { min: 0, step: 0.01 },

                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="p" component="h6" fullWidth>
                        Price
                        <StarIcon />
                      </Typography>
                      <TextField
                        fullWidth
                        required
                        type="number"
                        inputProps={{
                          inputMode: "numeric",
                          step: "0.01",
                          min: 0,
                        }}
                        variant="outlined"
                        size="small"
                        value={this.state.editForm.price}
                        onChange={(event) => {
                          let { value } = event.target;
                          let editForm = this.state.editForm;
                          editForm.price = parseFloat(value);
                          this.setState({ editForm: editForm });
                        }}
                        onClick={() => {
                          this.setState({ apiResponseMessage: null });
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4}>
                                            <Typography variant="p" component="h6" fullWidth>
                                                Compare at Price
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                required
                                                type="number"
                                                inputProps={{ inputMode: 'numeric', step: "0.01", min: 0 }}
                                                variant="outlined"
                                                size="small"
                                                value={this.state.editForm.compare_at_price}
                                                onChange={(event) => {
                                                    let { value } = event.target;
                                                    let editForm = this.state.editForm;
                                                    editForm.compare_at_price = parseFloat(value);
                                                    this.setState({ editForm: editForm });
                                                }}
                                                onClick={() => {
                                                    this.setState({ apiResponseMessage: null });
                                                }}
                                            />
                                        </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="p" component="h6" fullWidth>
                        Stock
                        <StarIcon />
                      </Typography>
                      <TextField
                        fullWidth
                        required
                        type="number"
                        inputProps={{ inputMode: "numeric", min: 0 }}
                        variant="outlined"
                        size="small"
                        value={this.state.editForm.stock}
                        onChange={(event) => {
                          let { value } = event.target;
                          let editForm = this.state.editForm;
                          editForm.stock = parseFloat(value);
                          this.setState({ editForm: editForm });
                        }}
                        onClick={() => {
                          this.setState({ apiResponseMessage: null });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <Typography variant="p" component="h6" fullWidth>
                        Weight
                        <StarIcon />
                      </Typography>
                      <TextField
                        fullWidth
                        required
                        type="number"
                        variant="outlined"
                        size="small"
                        value={this.state.editForm.weight}
                        onChange={(event) => {
                          let { value } = event.target;
                          let editForm = this.state.editForm;
                          editForm.weight = parseFloat(value);
                          this.setState({ editForm: editForm });
                        }}
                        onClick={() => {
                          this.setState({ apiResponseMessage: null });
                        }}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position="start">kg</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      {APIService.GetStoreConfig("assembly_allowed") == 1 && (
                        <>
                          <Typography
                            className={"assembly-allowed"}
                            variant="p"
                            component="h6"
                            fullWidth
                          >
                            <span>Assembly Time</span>
                            <span className="small">
                              Don&apos;t need assembly?{" "}
                              <Link to="/app-settings">App settings</Link>
                            </span>
                          </Typography>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            size="small"
                            min={0}
                            value={this.state.editForm.assembly_build}
                            onClick={() => {
                              this.setState({ apiResponseMessage: null });
                            }}
                            onChange={(event) => {
                              let { value } = event.target;
                              let editForm = this.state.editForm;
                              editForm.assembly_build = value;
                              this.setState({ editForm: editForm });
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  min
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                      {!APIService.GetStoreConfig("assembly_allowed") && (
                        <div className="assembly-allows">
                          <Typography
                            variant="p"
                            component="h6"
                            fullWidth
                            className="currently-turned-off"
                          >
                            <AssemblyIcon /> ASSEMBLY IS CURRENTLY TURNED OFF
                          </Typography>
                          <Typography variant="p" component="h6" fullWidth>
                            <span className="small">
                              You must{" "}
                              <Link to="/app-settings">enable assembly</Link> to
                              add assembly times for this item.
                            </span>
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <DialogActions>
                    <Button
                      onClick={this.props.onClose}
                      variant="outlined"
                      className="button-cancel"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        let editForm = this.state.editForm;
                        editForm.apply_to_all_variants = false;
                        this.setState({ editForm: editForm });
                        this.editFormSubmit();
                      }}
                      variant="contained"
                      className="button-yes"
                    >
                      SAVE
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </>
          )}
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddEditProduct);
