import React, { Component } from "react";

// import EmailIcon from "@material-ui/icons/Email";

import "./HelpPage.scss";
export default class HelpFooter extends Component {
  render() {
    return (
      <div className="contact-info">
        <h1>need further assistance?</h1>
        <p>Don&#39;t worry, we&#39;re here to help!</p>
        <div className="contact-us">
          <img src="/images/shopifyHelpCentre_ContactSupport.jpg" />
          <a
            rel="noreferrer"
            target="_blank"
            href="https://bjshomedelivery.com/"
            className="contact-support"
          >
            CONTACT US
          </a>
        </div>
      </div>
    );
  }
}
