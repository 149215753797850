import React, { useRef } from "react";
import { ReactComponent as BackToTopArrowIcon } from "../../assets/img/backToTop_Arrow.svg";
import { ReactComponent as BackToTopLineIcon } from "../../assets/img/backToTop_Line.svg";

import TopBarLayout from "./TopBarLayout";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import { Paper } from "@material-ui/core/";
import { ReactComponent as BJSTwoManLogo } from "../../assets/img/BJSTwoMan_Logo.svg";

import "./layout4.scss";

import { AppBar, makeStyles, Toolbar, Box } from "@material-ui/core";

const height = window.innerHeight - 74; //document.getElementsByTagName("body")[0].clientHeight;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100%)`,
    },
  },
  main: {
    scrollBehavior: "smooth",

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100%)`,
    },
    overflow: "hidden",
    height: `${height}px`,
  },
  main2: {
    height: `${height}px`,
    overflow: "hidden",
    overflowY: "scroll",
  },
  logo: {
    display: "block",
    width: "130px",
    margin: "auto",
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    top: "83px",
    background: "#e5e5e5",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paper: {
    margin: "auto",
    marginTop: "0px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  horizontalLine: {
    padding: "2px!important",
    background: "#3A3630 !important",
  },
}));

export default function layout4(data) {
  const routes = data.routes;
  const classes = useStyles();
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const scrollToRef = () =>
    document.getElementById("main-scroll").scrollTo(0, 0);

  return (
    <div className={classes.grow}>
      {!localStorage.getItem("store_config") && (
        <AppBar position="static" className="menu">
          <Toolbar>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://bjshomedelivery.com/"
              className="contact-support"
            >
              <BJSTwoManLogo className={classes.logo} />
            </a>
            <div className={classes.grow} />{" "}
          </Toolbar>{" "}
        </AppBar>
      )}
      {localStorage.getItem("store_config") && <TopBarLayout />}
      <Box className={classes.horizontalLine}> </Box>{" "}
      <Box className={classes.main}>
        <Box
          mt={1}
          p={1}
          className={classes.main2 + " main"}
          id="main-scroll"
          style={{ padding: 0, marginTop: "0px!important" }}
        >
          <GridItem xs={12} sm={12} md={12} style={{ padding: 0 }}>
            <Paper
              elevation={3}
              p={5}
              className={classes.paper + " Help-layout"}
            >
              <Switch>
                {" "}
                {routes.map((route, i) => (
                  <RouteWithSubRoutes
                    executeScroll={executeScroll}
                    key={i}
                    {...route}
                  />
                ))}{" "}
              </Switch>{" "}
              <Box mt={1} p={1} className="footer">
                <p>
                  © BJS Distribution Ltd, 2022 - All rights reserved.{" "}
                  <a
                    href="https://bjshomedelivery.com/"
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    www.bjsfamily.com
                  </a>
                </p>
                <button
                  id="back-to-top"
                  className="back-to-top"
                  onClick={executeScroll}
                >
                  <BackToTopLineIcon className="line" />
                  <BackToTopArrowIcon className="arrow" />
                </button>
              </Box>
            </Paper>{" "}
          </GridItem>{" "}
        </Box>{" "}
      </Box>{" "}
    </div>
  );
}

layout4.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...props}
          routes={route.routes}
          executeScroll={route.executeScroll}
        />
      )}
    />
  );
}
