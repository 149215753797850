import React, { Component } from "react";
import HelpCategoryPage from "views/HelpPage/HelpCategoryPage.jsx";
import HelpPage from "views/HelpPage/HelpPage.jsx";

export default class IndexPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    let path = this.props.location.pathname.replace("/help", "");
    path = path.split("/");
    return (
      <>
        {path[1] && (
          <HelpCategoryPage
            executeScroll={this.props.executeScroll}
            category={path[1]}
            question={path[2] ? path[2] : ""}
          />
        )}
        {!path[1] && <HelpPage executeScroll={this.props.executeScroll} />}
      </>
    );
  }
}
