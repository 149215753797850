import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import ListItem from '@material-ui/core/ListItem';
import Divider from "@material-ui/core/Divider";
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

export default class Help extends Component {
  static ProductPage = (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon />
          </ListItemIcon>
          <ListItemText primary="Measurement of the product to be given in CM this will be used to calculate the Cube square" />
        </ListItem>
      </List>
    </div>
  );
  static DispatchesPage = (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon />
          </ListItemIcon>
          <ListItemText primary="- date range to be added for a week - in the filter section" />
        </ListItem>
      </List>
    </div>
  );
  static OrdersPage = (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon />
          </ListItemIcon>
          <ListItemText primary="- date range to be added for a week - in the filter section" />
        </ListItem>
      </List>
    </div>
  );
  static DeliverySchedulePage = (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon />
          </ListItemIcon>
          <ListItemText primary="To see when we deliver in your area, use the search engine to filter results. Search by area or the first few letters of the postcode." />
        </ListItem>
      </List>
    </div>
  );
  static SettingsPage = (
    <div>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
                  Auto Release Orders"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  This would indicate that the orders sent to BJS are in a
                  ‘Released’ state - release = true on the order attributes.
                  This means BJS systems will process the order straight away.
                  If auto release is false then a follow up call (release
                  products) is required for each product on the order. On
                  release of the last unreleased product BJS will release order
                  itself. We can update this terminology if needed.
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
                  Batch Orders"
            variant="body"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  This is just a mechanism of searching for the orders - eg.
                  retailer sends 50 orders to BJS, we want to be able to track
                  them together as a single inbound load - therefore we can
                  assign a “batch reference” (import reference field on the API)
                  and this way all the orders can be found quickly — (they will
                  be created individually hence at different times hence we
                  can’t find them by time). You can have batches which are
                  unreleased. these two options are not mutually exclusive. In
                  future we may want to provide an option here to configure the
                  format of the reference, ie is it date time, is it a custom
                  prefix?
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
                 Use Live API"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  This is to enable / disable Live or Staging environment.
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
            Auto Dispatch Order to BJS"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Enabling this setting will automatically create all Shopify
                  orders on BJS systems without the need to manually dispatch
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
            Auto Release Stock to BJS"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Enabling this setting will automatically release stock to BJS,
                  which makes an order ‘live’ for processing within BJS systems.
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
            Batch Orders"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  This function allows orders to be ‘batched’, assigning a
                  shared unique batch reference number to each order within a
                  batch. The orders can be batched manually or automatically; In
                  ‘manual’ mode, a user will need create a batch from despatches
                  whereas ‘auto batch’ function allows a user to schedule a
                  batch of orders to be dispatched to BJS at a specified time
                  interval i.e 10am, 12pm, 1pm.
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
            Assembly Allowed"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  If enabled, this function will send through a ‘build time’ for
                  any products that have a build time defined and assembly
                  enabled within the ‘Product’ management module..
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="
            API Mode"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  This indicates which environment a user is currently running
                </Typography>
              </React.Fragment>
            }
          />
          <Divider variant="inset" component="li" />
        </ListItem>
      </List>
    </div>
  );
}
