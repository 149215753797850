import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { ReactComponent as StoreSwitchIcon } from "../../../assets/img/icons_StoreSwitch.svg";

export default function StoresList() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const SetStores = (storeId) => {
    let stores = GetStores();
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].id == storeId) stores[i].is_selected = true;
      else stores[i].is_selected = false;
    }
    if (!stores) {
      // document.location = "/error/401";
      document.location = "/help";
    } else {
      localStorage.setItem("stores", JSON.stringify(stores));
      localStorage.removeItem("store_config");
    }

    handleClose();
    document.location = "/";
  };
  const GetStores = (is_only_seleced_store = false) => {
    var stores = localStorage.getItem("stores");
    if (stores && stores.length > 0) {
      stores = JSON.parse(stores);
      if (is_only_seleced_store == false) {
        return stores;
      } else {
        for (let i = 0; i < stores.length; i++) {
          if (stores[i].is_selected == true) {
            return stores[i];
          }
        }
        return stores[0];
      }
    }
  };
  const stores = GetStores();
  const selectedStore = GetStores(true);

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={"menu-link store-dropdown " + (anchorEl ? "open" : "")}
      >
        <span
          id="selected-store"
          data-selected-store-id={selectedStore ? selectedStore.id : null}
        >
          {selectedStore ? selectedStore.name.toLowerCase() : null}
        </span>{" "}
        <StoreSwitchIcon
          className={"store-switch-icon " + (anchorEl ? "open" : "")}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        className="store-selected-menu"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {stores &&
          stores.map((store) => (
            <MenuItem
              onClick={() => SetStores(store.id)}
              selected={store.is_selected}
              key={store.id}
              className="store-list"
            >
              <AddBusinessIcon className="store-icon" />{" "}
              {store.name.toLowerCase()}
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
}
