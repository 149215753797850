import React, { Component } from "react";

class ConsignmentNoHelper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a
        rel="noreferrer"
        href={
          "https://system.bjshomedelivery.com/orders/view/?order=" +
          this.props.no.substring(1).split("-")[0]
        }
        target="_blank"
      >
        {this.props.children ?? this.props.no}
      </a>
    );
  }
}

export default ConsignmentNoHelper;
