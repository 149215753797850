import React, { useRef, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";

import { IconButton, TextField } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../../assets/img/icons_Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import "./../layouts/layout1.scss";

/**
 * Component that alerts if you click outside of it
 */
export default function SearchBarHelper(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSearchBoxClicked(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const [isSearchBoxClicked, setIsSearchBoxClicked] = React.useState(false);
  return (
    <div className={"search-bar"} ref={wrapperRef}>
      {isSearchBoxClicked && (
        <TextField
          autoFocus={true}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              props.onSubmit();
              setIsSearchBoxClicked(!isSearchBoxClicked);
            }
          }}
          fullWidth
          variant="outlined"
          placeholder={props.placeholder}
          size="small"
          className="search-box"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    props.onSubmit();
                    setIsSearchBoxClicked(false);
                  }}
                  aria-label="toggle password visibility"
                  edge="end"
                  className="search-btn-inside-text-box"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <IconButton
        variant="contained"
        onClick={() => {
          setIsSearchBoxClicked(!isSearchBoxClicked);
        }}
        className="search-btn"
      >
        {isSearchBoxClicked ? <CloseIcon /> : <SearchIcon />}
      </IconButton>
    </div>
  );
}
