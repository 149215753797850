import React from "react";
import {
  Button,
  DialogActions,
  // Dialog,
  // DialogContent,
  // DialogTitle,
  IconButton,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText
} from "@material-ui/core/";
// import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import PropTypes from "prop-types";
import { ReactComponent as CancelIcon } from "../../assets/img/ctaIcons_Cancel.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/icons_Close.svg";
import { ReactComponent as CheckIcon } from "../../assets/img/navIcons_Check.svg";
import LoadingHelper from "./LoadingHelper.jsx";
import OrderStatus from "./OrderStatus.jsx";
// import OrderStatusEnum from "./OrderStatusEnum.jsx";
// import ProgressBarHelper from "./ProgressBarHelper.jsx";

export default function OrderActionResponsePopup(props) {
  const result = props.result;
  const refreshData = props.refreshData;
  // const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  // const [value, setValue] = React.useState("Dione");

  const [isOpenFailurePopup, setIsOpenFailurePopup] = React.useState(null);
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = React.useState(false);
  const [success, setSuccess] = React.useState([]);
  const [failure, setFailure] = React.useState([]);
  const [isResponseReturned, setIsResponseReturned] = React.useState(false);

  const handleClose = () => {
    refreshData();
  };

  const HandlePopup = () => {
    var response = props.result;
    let success = [];
    let failure = [];

    response.forEach((orderResponse) => {
      let message = orderResponse.message;
      message = message.replace("#" + orderResponse.order_number + " : ", "");
      message = message.replace(
        "<status_id>",
        OrderStatus({ code: orderResponse.order_status })
      );
      message = message.replace(
        "is already Exception",
        "is already in Exception Tab"
      );

      let orderNumber = orderResponse.order_number;
      let isAdded = false;
      if (orderResponse.status) {
        success.forEach((response, index) => {
          if (response.message == message) {
            success[index].orders.push(orderNumber);
            isAdded = true;
          }
        });
        if (!isAdded) {
          success.push({ message: message, orders: [orderNumber] });
        }
      } else {
        failure.forEach((response, index) => {
          if (response.message == message) {
            failure[index].orders.push(orderNumber);
            isAdded = true;
          }
        });
        if (!isAdded) {
          failure.push({ message: message, orders: [orderNumber] });
        }
      }
    });

    setIsOpenFailurePopup(failure.length > 0);
    setIsOpenSuccessPopup(success.length > 0);
    setSuccess(success);
    setFailure(failure);
    setIsResponseReturned(true);
  };

  return (
    <>
      {result !== true && isResponseReturned == false && HandlePopup()}
      {result === true && (
        <LoadingHelper
          loaderMessage={props.loaderMessage}
          onClose={handleClose}
        />
      )}
      {result !== true &&
        (isOpenFailurePopup === true || isOpenSuccessPopup === true) && (
          <div className="order-response-popup">
            {isOpenSuccessPopup && (
              <div className="popup success-popup">
                <IconButton
                  className="close-icon"
                  onClick={() => {
                    setIsOpenSuccessPopup(false);
                    if (!isOpenFailurePopup) handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <div className="popup-body">
                  <h1>
                    <CheckIcon /> Success
                  </h1>
                  <div className="popup-content">
                    {success.map((row) => (
                      <>
                        <p>
                          {row.message
                            .replace(
                              "Order",
                              row.orders.length == 1 ? "Order" : "Orders"
                            )
                            .replace(
                              "order",
                              row.orders.length == 1 ? "order" : "orders"
                            )
                            .replace(
                              " is ",
                              row.orders.length == 1 ? " is " : " are "
                            )
                            .replace(".", "")
                            .replace(
                              "Product",
                              row.orders.length == 1 ? "Product" : "Products"
                            )}
                          :
                        </p>
                        <p className="order-no">
                          {"'" + row.orders.join("', '") + "'"}
                        </p>
                      </>
                    ))}

                    <DialogActions>
                      <Button
                        variant="contained"
                        className="button-cancel success"
                        onClick={() => {
                          setIsOpenSuccessPopup(false);
                          if (!isOpenFailurePopup) handleClose();
                        }}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </div>
            )}
            {isOpenFailurePopup && (
              <div className="popup popup-failure">
                <IconButton
                  className="close-icon"
                  onClick={() => {
                    setIsOpenFailurePopup(false);
                    if (!isOpenSuccessPopup) handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <div className="popup-body">
                  <h1>
                    <CancelIcon /> there was a problem
                  </h1>
                  <div className="popup-content">
                    {failure.map((row) => (
                      <>
                        <p>
                          {row.message
                            .replace(
                              "Order",
                              row.orders.length == 1 ? "Order" : "Orders"
                            )
                            .replace(
                              "order",
                              row.orders.length == 1 ? "order" : "orders"
                            )
                            .replace(
                              " is ",
                              row.orders.length == 1 ? " is " : " are "
                            )
                            .replace(".", "")
                            .replace(
                              "Product",
                              row.orders.length == 1 ? "Product" : "Products"
                            )}
                          :
                        </p>
                        <p className="order-no">
                          {"'" + row.orders.join("', '") + "'"}
                        </p>
                      </>
                    ))}

                    <DialogActions>
                      <Button
                        variant="contained"
                        className="button-cancel failure"
                        onClick={() => {
                          setIsOpenFailurePopup(false);
                          if (!isOpenSuccessPopup) handleClose();
                        }}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
}
