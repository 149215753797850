import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./Layout1Components.scss";
import { ReactComponent as IconEnvironmentStaging } from "../../../assets/img/environmentIcons_Staging.svg";
import { ReactComponent as IconEnvironmentProduction } from "../../../assets/img/environmentIcons_Production.svg";
import APIService from "../../../services/APIService.js";
import AppSettings from "app-settings";
import ApiResponseError from "../../helper/ApiResponseError.jsx";
import LoadingHelper from "../../helper/LoadingHelper.jsx";
import APIResponsePopupHelper from "../../helper/APIResponsePopupHelper.jsx";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ChangeStorePopup(props) {
  const handleClose = () => {
    props.openIsStagingOrLiveFunction();
  };
  const [responseMessage, setResponseMessage] = React.useState(false);

  const ChangeApiMode = () => {
    // setResponseMessage(null);
    APIService.POST(AppSettings.UpdateStoreApiMode)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          let StoreConfig = localStorage.getItem("store_config");
          StoreConfig = JSON.parse(StoreConfig);
          StoreConfig.is_live_api = !StoreConfig.is_live_api;
          localStorage.setItem("store_config", JSON.stringify(StoreConfig));
          response.message =
            "You are in " +
            (StoreConfig.is_live_api ? "production" : "staging") +
            " mode";
          props.isLiveOrStaging(StoreConfig.is_live_api);
        }

        setResponseMessage(response);
      })
      .catch(() => {
        setResponseMessage(
          <ApiResponseError
            onClick={() => {
              setResponseMessage(null);
            }}
            response={{
              message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
            }}
          />
        );
      });
  };

  return (
    <React.Fragment>
      {responseMessage === null && <LoadingHelper />}
      {responseMessage && (
        <APIResponsePopupHelper
          result={responseMessage}
          successClose={() => {
            props.openIsStagingOrLiveFunction(false);
            // props.setIsLiveOrStaging(StoreConfig.is_live_api);
          }}
          failureClose={() => {
            setResponseMessage(false);
          }}
        />
      )}
      <BootstrapDialog
        className="popup-component"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={responseMessage === false}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent dividers>
          {!APIService.GetStoreConfig("is_live_api") ? (
            <IconEnvironmentProduction />
          ) : (
            <IconEnvironmentStaging />
          )}
          <h1>
            Switch to{" "}
            {!APIService.GetStoreConfig("is_live_api")
              ? "Production"
              : "Staging"}{" "}
            mode
          </h1>
          <Typography gutterBottom>
            You are about to switch to ‘
            {!APIService.GetStoreConfig("is_live_api")
              ? "Production"
              : "Staging"}{" "}
            Mode’, are you sure you want to do this?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="button-cancel"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="button-yes"
            onClick={ChangeApiMode}
          >
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
