export default class OrderPopupTabIndexEnum {
  static OVERVIEW() {
    return 0;
  }
  static AUDIT_LOG() {
    return 1;
  }
  static ORDER_NOTES() {
    return 2;
  }
}
