import * as React from "react";

import { IconButton } from "@material-ui/core";
import { ReactComponent as LeftArrow } from "../../assets/img/navigationIcons_LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/img/navigationIcons_RightArrow.svg";

export default function PaginationComponent(props) {
  const handlePagination = (activePage) => {
    if (activePage > props.count || activePage < 0) {
      return false;
    }
    props.handlePageChange(activePage);
  };

  return (
    <div className={"pagination"}>
      <IconButton
        disabled={props.start - props.limit < 0}
        size="small"
        className={"text-black "}
        onClick={() => {
          handlePagination(props.start - props.limit);
        }}
      >
        <LeftArrow />
      </IconButton>
      <span className="main-span">
        {props.count != 0 ? props.start + 1 : 0}
        {" - "}
        {props.start + props.limit < props.count
          ? props.start + props.limit
          : props.count}{" "}
        <span>of</span> {props.count}
      </span>
      <IconButton
        size="small"
        disabled={props.start + 1 + props.limit > props.count ? true : false}
        className={"text-black "}
        onClick={() => {
          handlePagination(props.start + props.limit);
        }}
      >
        <RightArrow />
      </IconButton>
    </div>
  );
}
